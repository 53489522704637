import {
  ADD_FUNDS_TO_USER,
  ADD_WALLET,
  ADD_WALLET_TO_USER,
  ADMIN_ADD_FUND,
  CHANGE_WALLET_STATUS,
  CURRENT_USER_WALLET_ID,
  GET_ORG_WALLET_TRANSACTIONS,
  GET_USERS,
  GET_WALLET,
  GET_WALLET_TRANSACTIONS,
  MY_WALLET,
  SEARCH_USERS,
  NAV_OPTION_SELECTED
} from "./actionTypes";

export const getWallet = (payload) => {
  return {
    type: GET_WALLET,
    payload,
  };
};

export const addWallet = (payload) => {
  return {
    type: ADD_WALLET,
    payload,
  };
};

export const adminAddsFunds = (payload) => {
  return {
    type: ADMIN_ADD_FUND,
    payload,
  };
};

export const getWalletTransactions = () => {
  return {
    type: GET_WALLET_TRANSACTIONS,
  };
};

export const addWalletToUser = (payload) => {
  return {
    type: ADD_WALLET_TO_USER,
    payload,
  };
};

export const getUsers = (payload) => {
  return {
    type: GET_USERS,
    payload,
  };
};

export const searchUsers = (payload) => {
  return {
    type: SEARCH_USERS,
    payload,
  };
};

export const addFundsToUser = (payload) => {
  return {
    type: ADD_FUNDS_TO_USER,
    payload,
  };
};
export const setCurrentUserWalletId = (payload) => {
  return {
    type: CURRENT_USER_WALLET_ID,
    payload,
  };
};

export const getOrgWalletTransactions = (payload) => {
  return {
    type: GET_ORG_WALLET_TRANSACTIONS,
    payload,
  };
};

export const getMyWallet = (payload) =>{
  return {
    type: MY_WALLET,
    payload
  }
}

export const changeWalletStatus = (payload) =>{
  return {
    type: CHANGE_WALLET_STATUS,
    payload
  }
}

export const navSelected = (payload) =>{
  return{
    type: NAV_OPTION_SELECTED,
    payload
  }
}