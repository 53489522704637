import {
  GET_COUNTRIES_REQUEST,
  GET_CITIES_REQUESTED,
  GET_STATES_REQUESTED,
  CHANGE_STATE_ID,
  CHANGE_COUNTRY_ID
} from '../constants/ActionTypes';

export const getCountries = (id) => {
  return {
    type: GET_COUNTRIES_REQUEST,
    payload: id
  }
}

export const changeStateId = (id) => {
  return {
    type: CHANGE_STATE_ID,
    payload: id
  }
}

export const changeCountryId = (id) => {
  return {
    type: CHANGE_COUNTRY_ID,
    payload: id
  }
}
export const getStates = (id) => {
  return {
    type: GET_STATES_REQUESTED,
    payload: id
  }
}

export const getCities = (id) => {
  return {
    type: GET_CITIES_REQUESTED,
    payload: id
  }
}