import React, { Component } from "react";
import IntlMessages from "../../../../../util/IntlMessages";
import { CustomizedTooltip } from "../../../../core/components/cv-tooltips/CustomizedTooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import {
  withStyles,
  TextField,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  FormHelperText,
} from "@material-ui/core";
import CustomScrollbars from "../../../../../util/CustomScrollbars";
import { ids as cIds } from "../../../../core/utils/countries-ids";

const styles = (theme) => ({
  component: {
    margin: theme.spacing(2),
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: theme.spacing(2),
  },
  status: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  divider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  infoStyle: {
    marginTop: theme.spacing(3.5),
    width: 20,
    height: 20,
    marginLeft: theme.spacing(1),
    color: "black",
  },
  phoneInput: {
    borderBottomStyle: "solid",
    borderWidth: 1,
    borderColor: "#707B7C",
    height: 30,
  },
});

const defaultBasicInfo = {
  legalName: "",
  status: false,
  clinicType: "",
  phoneNumber: "",
  address1: "",
  address2: "",
  city: "",
  country: "",
  province: "",
  postalCode: "",
};

class OrgBasicInfo extends Component {
  constructor(props) {
    super(props);
    const basicInfo = this.props.basicInfo
      ? this.props.basicInfo
      : defaultBasicInfo;
    this.state = {
      basicInfo,
      open: false,
      emailError: "",
      phoneNumberError: "",
      countryId: "",
      stateId: "",
    };
  }

  componentDidMount() {
    this.props.getCountries({ ids: cIds });
    this.props.getOrgTypes();
    this.props.getSpecialities(this.state.basicInfo.orgType);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.basicInfo !== this.props.basicInfo &&
      prevProps.basicInfo.legalName === defaultBasicInfo.legalName
    ) {
      this.setState({ basicInfo: this.props.basicInfo });
    }
    if (prevProps.countryId !== this.props.countryId) {
      this.props.getStates({ id: this.props.countryId });
    }
    if (prevProps.stateId !== this.props.stateId) {
      let countryId =
        typeof this.props.countryId === "string"
          ? this.props.countryId.substring(2, this.props.countryId.length)
          : this.props.countryId;

      this.props.getCities({ id: this.props.stateId });
    }

    if (
      prevProps.countries !== this.props.countries &&
      this.props.countries.length > 0
    ) {
      let newCountries = this.props.countries.filter((country) => {
        return country.name === this.state.basicInfo.country;
      });
      if (newCountries.length > 0) {
        this.props.changeCountryId(newCountries[0].id);
      }
    }

    if (
      prevProps.states !== this.props.states &&
      this.props.states.length > 0
    ) {
      let newStates = this.props.states.filter((state) => {
        return state.name === this.state.basicInfo.province;
      });
      if (newStates.length > 0) {
        this.props.changeStateId(newStates[0].id);
      }
    }
  }

  handleTypeChange = (e) => {
    const basicInfo = {
      ...this.state.basicInfo,
    };
    basicInfo.orgType = e.target.value;
    this.setState({ basicInfo }, () =>
      this.props.onOrgBasicInfoUpdate(this.state.basicInfo)
    );
  };
  handleSpecialityChange = (e) => {
    const basicInfo = {
      ...this.state.basicInfo,
    };
    basicInfo.speciality = e.target.value;
    this.setState({ basicInfo }, () =>
      this.props.onOrgBasicInfoUpdate(this.state.basicInfo)
    );
  };

  handleStatusTypeChange = (e) => {
    const basicInfo = {
      ...this.state.basicInfo,
    };
    basicInfo.status = e.target.value;
    this.setState({ basicInfo }, () =>
      this.props.onOrgBasicInfoUpdate(this.state.basicInfo)
    );
  };

  updateErrors = () => {
    const { phoneNumberError } = this.state;
    const invalid = phoneNumberError;
    this.props.onUpdateBasicErrors(invalid ? true : false);
  };

  handleIconButtonRequestClose = () => {
    this.setState({ open: false });
  };

  handleIconButtonRequestOpen = () => {
    this.setState({ open: true });
  };

  handlePhoneChange = (event) => {
    const basicInfo = {
      ...this.state.basicInfo,
    };

    if (isPossiblePhoneNumber(event)) {
      this.setState({ phoneNumberError: "" }, () => this.updateErrors());
    } else {
      this.setState({ phoneNumberError: "PhoneNumber is incorrect" }, () =>
        this.updateErrors()
      );
    }
    basicInfo["phoneNumber"] = event;
    this.setState({ basicInfo }, () =>
      this.props.onOrgBasicInfoUpdate(this.state.basicInfo)
    );
  };

  handleChange = (name) => (event) => {
    const basicInfo = {
      ...this.state.basicInfo,
    };

    basicInfo[name] = event.target.value;
    this.setState({ basicInfo }, () =>
      this.props.onOrgBasicInfoUpdate(this.state.basicInfo)
    );
  };

  selectCountry = (e, key) => {
    const basicInfo = {
      ...this.state.basicInfo,
    };
    if (basicInfo.country !== e.target.value) {
      basicInfo.province = "";
      basicInfo.city = "";
    }
    basicInfo.country = e.target.value;

    this.setState({ basicInfo }, () => {
      this.props.onOrgBasicInfoUpdate(this.state.basicInfo);
      this.props.changeCountryId(key.key);
    });
  };

  selectState = (e, key) => {
    const basicInfo = {
      ...this.state.basicInfo,
    };

    if (basicInfo.province !== e.target.value) {
      basicInfo.city = "";
    }
    basicInfo.province = e.target.value;
    this.setState({ basicInfo }, () => {
      this.props.onOrgBasicInfoUpdate(this.state.basicInfo);
      this.props.changeStateId(key.key);
    });
  };

  selectRegion = (e) => {
    const basicInfo = {
      ...this.state.basicInfo,
    };

    basicInfo["city"] = e.target.value;
    this.setState({ basicInfo }, () =>
      this.props.onOrgBasicInfoUpdate(this.state.basicInfo)
    );
  };

  render() {
    const {
      classes,
      edit,
      adminCreate,
      width,
      orgAdminEdit,
      countries,
      states,
      cities,
    } = this.props;
    const web = width > 700 ? true : false;
    const statusTypes = [
      {
        displayComp: <IntlMessages id="org.status.active" />,
        value: true,
        id: 1,
      },
      {
        displayComp: <IntlMessages id="org.status.inactive" />,
        value: false,
        id: 2,
      },
    ];

    return (
      <React.Fragment>
        <CustomScrollbars
          className="module-list-scroll scrollbar"
          style={{
            height:
              edit || adminCreate
                ? "calc(100vh - 360px)"
                : "calc(100vh - 270px)",
          }}
        >
          <div className={classes.component} data-test="orgBasicInfoComponent">
            <h3>
              <b>
                <IntlMessages id="org.info" />
              </b>
            </h3>
            <Grid container spacing={3}>
              <Grid item xs={web ? 6 : 12}>
                <TextField
                  id="standard-name"
                  label={<IntlMessages id="org.legalName" />}
                  value={
                    this.state.basicInfo.legalName.charAt(0).toUpperCase() +
                    this.state.basicInfo.legalName.slice(1)
                  }
                  fullWidth
                  inputProps={{ readOnly: orgAdminEdit ? true : false }}
                  onChange={this.handleChange("legalName")}
                  required
                />
              </Grid>
              <Grid item xs={web ? 6 : 12}>
                <FormControl className={classes.formControl} required fullWidth>
                  <InputLabel htmlFor="owner-helper">
                    <IntlMessages id="org.type.default" />
                  </InputLabel>
                  <Select
                    required
                    value={this.state.basicInfo.orgType}
                    onChange={(e) => {
                      this.props.getSpecialities(e.target.value);
                      this.handleTypeChange(e);
                    }}
                    style={{ marginBottom: -7 }}
                    inputProps={{ readOnly: orgAdminEdit ? true : false }}
                    input={<Input name="type" id="owner-helper" />}
                  >
                    {this.props.organizationTypes.map((gd) => (
                      <MenuItem key={gd.id} value={gd.id}>
                        {gd.org_type.charAt(0).toUpperCase() +
                          gd.org_type.slice(1)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={web ? 6 : 12}>
                <FormControl className={classes.formControl} required fullWidth>
                  <InputLabel htmlFor="owner-helper">
                    <IntlMessages id="org.speciality" />
                  </InputLabel>
                  <Select
                    required
                    value={this.state.basicInfo.speciality[0]}
                    onChange={(e) => this.handleSpecialityChange(e)}
                    style={{ marginBottom: -7 }}
                    inputProps={{ readOnly: orgAdminEdit ? true : false }}
                    input={<Input name="type" id="owner-helper" />}
                  >
                    {this.props.organizationSpecialties.map((gd) => (
                      <MenuItem key={gd.id} value={gd.speciality}>
                        {gd.speciality.charAt(0).toUpperCase() +
                          gd.speciality.slice(1)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={web ? 6 : 12}>
                <FormControl fullWidth>
                  <span className="mb-0 jr-fs-sm" style={{ color: "gray" }}>
                    {this.props.lang === "en"
                      ? "Phone Number *"
                      : "رقم الهاتف *"}
                  </span>
                  <PhoneInput
                    international={true}
                    withCountryCallingCode={true}
                    style={{ width: "100%" }}
                    id="standard-phoneNumber"
                    inputClassName={classes.phoneInput}
                    countries={["EG", "US", "AU"]}
                    value={this.state.basicInfo.phoneNumber}
                    onChange={(event) => this.handlePhoneChange(event)}
                    required
                  />
                  {this.state.phoneNumberError && (
                    <FormHelperText id="component-error-text" error>
                      {this.state.phoneNumberError}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {((edit && !orgAdminEdit) || adminCreate) && (
                <Grid item xs={web ? 6 : 12} className={classes.status}>
                  <FormControl
                    className={classes.formControl}
                    required
                    fullWidth
                  >
                    <InputLabel htmlFor="owner-helper">
                      <IntlMessages id="org.status.default" />
                    </InputLabel>
                    <Select
                      id="standard-middle-2-name"
                      label={<IntlMessages id="org.status.default" />}
                      value={this.state.basicInfo.status}
                      inputProps={{
                        readOnly:
                          (edit && !orgAdminEdit) || adminCreate ? false : true,
                      }}
                      onChange={(e) => this.handleStatusTypeChange(e)}
                      style={{ marginBottom: -7 }}
                      input={<Input name="status" />}
                    >
                      {statusTypes.map((gd) => (
                        <MenuItem key={gd.id} value={gd.value}>
                          {gd.displayComp}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <CustomizedTooltip
                    id="tooltip-controlled"
                    title={<IntlMessages id="org.basicInfoTip" />}
                    onClose={this.handleIconButtonRequestClose}
                    enterDelay={100}
                    leaveDelay={100}
                    onOpen={this.handleIconButtonRequestOpen}
                    open={this.state.open}
                    placement="bottom"
                  >
                    <InfoOutlinedIcon className={classes.infoStyle} />
                  </CustomizedTooltip>
                </Grid>
              )}
              <Grid item xs={12} className={classes.divider}>
                <Divider />
              </Grid>
            </Grid>
            <h3>
              <b>
                <IntlMessages id="org.legalAddress" />
              </b>
            </h3>
            <Grid container spacing={3}>
              <Grid item xs={web ? 6 : 12}>
                <TextField
                  id="standard-middle-1-name"
                  label={<IntlMessages id="org.address1" />}
                  value={this.state.basicInfo.address1.street}
                  fullWidth
                  onChange={this.handleChange("address1")}
                  required
                />
              </Grid>
              <Grid item xs={web ? 6 : 12}>
                <TextField
                  id="standard-address-1-name"
                  label={<IntlMessages id="org.address2" />}
                  value={this.state.basicInfo.address2}
                  fullWidth
                  onChange={this.handleChange("address2")}
                />
              </Grid>
              <Grid item xs={web ? 6 : 12}>
                <FormControl fullWidth required>
                  <InputLabel htmlFor="owner-helper" style={{ color: "gray" }}>
                    <IntlMessages id="user.country" />
                  </InputLabel>
                  <Select
                    fullWidth
                    value={this.state.basicInfo.country}
                    onChange={(e, key) => this.selectCountry(e, key)}
                    input={<Input name="country" />}
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.id} value={country.name}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={web ? 6 : 12}>
                <FormControl fullWidth required>
                  <InputLabel htmlFor="owner-helper" style={{ color: "gray" }}>
                    <IntlMessages id="org.province" />
                  </InputLabel>
                  <Select
                    fullWidth
                    disabled={!this.state.basicInfo.country}
                    value={this.state.basicInfo.province}
                    onChange={(e, key) => this.selectState(e, key)}
                    input={<Input name="state" />}
                  >
                    {this.state.basicInfo.country &&
                      states.map((state) => (
                        <MenuItem key={state.id} value={state.name}>
                          {state.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={web ? 6 : 12}>
                <FormControl fullWidth required>
                  <InputLabel htmlFor="owner-helper" style={{ color: "gray" }}>
                    <IntlMessages id="user.city" />
                  </InputLabel>
                  <Select
                    fullWidth
                    disabled={!this.state.basicInfo.province}
                    value={this.state.basicInfo.city}
                    onChange={(e) => this.selectRegion(e)}
                    input={<Input name="city" />}
                  >
                    {this.state.basicInfo.province &&
                      cities.map((city) => (
                        <MenuItem key={city.id} value={city.name}>
                          {city.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={web ? 6 : 12}>
                <TextField
                  id="standard-city-name"
                  label={<IntlMessages id="org.postalCode" />}
                  value={this.state.basicInfo.postalCode}
                  fullWidth
                  type="number"
                  onChange={this.handleChange("postalCode")}
                />
              </Grid>
            </Grid>
          </div>
        </CustomScrollbars>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(OrgBasicInfo);
