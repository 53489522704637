import {
  GET_TRANSACTION_BY_ID_REQUEST,
  GET_TRANSACTION_BY_ID_SUCCESS,
  GET_TRANSACTION_BY_ID_FAILED,
  REFUND_TRANSACTION_REQUEST,
  REFUND_TRANSACTION_SUCCESS,
  REFUND_TRANSACTION_FAILED,
  PAYOUT_TRANSACTION_REQUEST,
  PAYOUT_TRANSACTION_SUCCESS,
  PAYOUT_TRANSACTION_FAILED
} from '../constants/actionTypes';

export const getTransactionById = (transactionId) => {
  return {
    type: GET_TRANSACTION_BY_ID_REQUEST,
    payload: transactionId
  }
}

export const getTransactionsByIdSuccess = (transaction) => {
  return {
    type: GET_TRANSACTION_BY_ID_SUCCESS,
    payload: transaction
  }
}

export const getTransactionByIdFailed = () => {
  return {
    type: GET_TRANSACTION_BY_ID_FAILED
  }
}

export const refundTransaction = (transactionId) => {
  return {
    type: REFUND_TRANSACTION_REQUEST,
    payload: transactionId
  }
}

export const refundTransactionSuccess = (transactionDetails) => {
  return {
    type: REFUND_TRANSACTION_SUCCESS,
    payload: transactionDetails
  }
}

export const refundTransactionFailed = () => {
  return {
    type: REFUND_TRANSACTION_FAILED
  }
}

export const payoutTransaction = (transactionId) => {
  return {
    type: PAYOUT_TRANSACTION_REQUEST,
    payload: transactionId
  }
}

export const payoutTransactionSuccess = (transactionDetails) => {
  return {
    type: PAYOUT_TRANSACTION_SUCCESS,
    payload: transactionDetails
  }
}

export const payoutTransactionFailed = () => {
  return {
    type: PAYOUT_TRANSACTION_FAILED
  }
}