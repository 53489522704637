import React from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	withStyles,
	CircularProgress
} from '@material-ui/core';
import IntlMessages from '../../../../util/IntlMessages';
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
	button: {
		margin: theme.spacing(1),
	},
	closeButton: {
		color: theme.palette.grey[500],
	}
})

const CvModal = ({
	isSaveEnabled,
	onSave,
	onClose,
	onBack,
	showButtons,
	onEnter,
	cancelIcon = true,
	fullScreen = false,
	open = false,
	title,
	saveLabel = "default.save",
	cancelLabel = "default.close",
	children,
	maxWidth = "sm",
	classes,
	loading = false,
	showOneButton = false,
	showBackButton = false,
	IntlMessageTitle = true,
	closeButton = false,
	isDirectionRTL = false
}) => {
	return (
		<React.Fragment>
			<Dialog
				onEnter={onEnter}
				maxWidth={maxWidth}
				fullWidth={true}
				fullScreen={fullScreen}
				onClose={() => onClose()}
				aria-labelledby="customized-dialog-title"
				open={open}
				scroll={'paper'}
			>

				<DialogTitle id="cv-dialog-title" onClose={() => onClose()}>
					{showBackButton && (
						<IconButton className="icon-btn" style={isDirectionRTL ? { marginRight: 8 } : { marginLeft: 8 }}
							onClick={onBack}>
							<i className={isDirectionRTL ? "zmdi zmdi-arrow-left" : "zmdi zmdi-arrow-right"} />
						</IconButton>
					)}
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
						{IntlMessageTitle ? <IntlMessages id={title} /> : title}
						{cancelIcon && (
							<IconButton aria-label="Close" className={classes.closeButton}
								onClick={onClose}>
								<CloseIcon />
							</IconButton>)}
					</div>
				</DialogTitle>
				<DialogContent>
					{children}
				</DialogContent>
				{showButtons &&
					<DialogActions>
						{closeButton &&
							<Button className={classes.button}
								variant="contained"
								onClick={() => onClose()}
							>
								<IntlMessages id={cancelLabel} />
							</Button>}
						<Button
							variant="contained"
							disabled={!isSaveEnabled}
							color="primary"
							className="btn-primary"
							onClick={() => onSave()}
						>

							{loading ? <CircularProgress size={25} style={{ color: 'white' }} /> : <IntlMessages id={saveLabel} />}
						</Button>
					</DialogActions>
				}
				{(showOneButton && closeButton) &&
					<DialogActions>
						<Button className={classes.button}
							variant="contained"
							onClick={() => onClose()}
						>
							<IntlMessages id={cancelLabel} />
						</Button>
					</DialogActions>
				}
			</Dialog>

		</React.Fragment>);
}

export default withStyles(styles)(CvModal);