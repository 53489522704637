export const pathConverter = (path) => {
  switch (path) {
    case "/app/clinic/users":
      return "pages.users";

    case "/app/clinic/providerCalendar":
      return "pages.providerCalendar";

    case "/app/clinic/reservations":
      return "pages.reservations";

    case "/app/clinic/user-profile":
      return "pages.userProfile";

    case "/app/clinic/clinic-profile":
      return "pages.clinicProfile";

    case "/app/my-health/events/events-list":
      return "pages.cases";

    case "/app/my-health/summary":
      return "pages.summary";

    case "/app/my-health/myClinics":
      return "pages.myClinics";

    case "/app/my-health/myReservations":
      return "pages.myReservations";

    case "/app/reservations/find-doctor":
      return "pages.findDoctor";

    case "/app/adminpanel/statistics":
      return "pages.statistics";

    case "/app/adminpanel/organizations":
      return "pages.organizations";

    case "/app/adminpanel/users":
      return "pages.adminUsers";

    case "/app/adminpanel/transactions":
      return "pages.transactions";

    case "/app/adminpanel/fees":
      return "pages.fees";

    case "/app/adminpanel/dailyReservations":
      return "pages.daily_reservations";

    case "/app/adminpanel/dailyChatReservations":
      return "pages.chat_daily_reservations";
    case "app/adminpanel/notifications":
      return "pages.notifications";

    default:
      return "pages.default";
  }
};
