import {
	ADD_EVENT,
	GET_EVENTS,
	EVENT_EDIT_REQUEST,
	ITEM_SELECTED,
	UPDATE_NOTE_TYPE,
	ADD_NOTE,
	NOTE_DELETE_REQUEST,
	SAVE_EDIT_REQUEST,
	UPDATE_SELECTED_NOTE,
	GET_NOTES_REQUEST,
	TRANSLATE_REQUEST,
	SAVE_COMMENT_REQUESTED,
	GET_COMMENTS_REQUEST,
	DELETE_COMMENT_REQUEST,
	RESET_NOTE_MODAL,
	UPDATE_NOTE_INFO,
	UPDATE_EVENT_FILTERS,
	UPDATE_TIME_FILTERS,
	RESET_EDIT_EVENT_MODE,
	SEARCH_EVENTS,
	UPDATE_DRAFT_VITAL_NOTE,
	RESET_ERROR,
	RESET_SUCCESS
} from './../constants/ActionTypes';

export const addEvent = (event) => {
	return {
		type: ADD_EVENT,
		payload: event
	}
}

export const updateEventFilters = (filterId) => {
	return {
		type: UPDATE_EVENT_FILTERS,
		payload: filterId
	}
}

export const updateTimeFilters = (filter) => {
	return {
		type: UPDATE_TIME_FILTERS,
		payload: filter
	}
}

export const getEvents = (userId) => {
	return {
		type: GET_EVENTS,
		payload: userId
	}
}

export const EventEditRequest = (event) => {
	return {
		type: EVENT_EDIT_REQUEST,
		payload: event
	}
}

export const itemSelected = (event) => {
	return {
		type: ITEM_SELECTED,
		payload: event
	}
}

export const updateNoteType = (label) => {
	return {
		type: UPDATE_NOTE_TYPE,
		payload: label
	}
}

export const addNote = (note) => {
	return {
		type: ADD_NOTE,
		payload: note
	}
}

export const noteDeleteRequest = (note) => {
	return {
		type: NOTE_DELETE_REQUEST,
		payload: note
	}
}

export const editNote = (note) => {
	return {
		type: SAVE_EDIT_REQUEST,
		payload: note
	}
}

export const updateSelectedNote = (note) => {
	return {
		type: UPDATE_SELECTED_NOTE,
		payload: note
	}
}

export const resetNoteModal = () => {
	return {
		type: RESET_NOTE_MODAL
	}
}

export const updateNote = (note) => {
	return {
		type: UPDATE_NOTE_INFO,
		payload: note
	}
}

export const getNotesRequest = (eventId) => {
	return {
		type: GET_NOTES_REQUEST,
		payload: eventId
	}
}

export const translate = (text) => {
	return {
		type: TRANSLATE_REQUEST,
		payload: text
	}
}

export const saveCommentRequest = (comment) => {
	return {
		type: SAVE_COMMENT_REQUESTED,
		payload: comment
	}
}

export const deleteCommentRequest = (comment) => {
	return {
		type: DELETE_COMMENT_REQUEST,
		payload: comment
	}
}

export const getCommentsRequest = (note) => {
	return {
		type: GET_COMMENTS_REQUEST,
		payload: note
	}
}

export const resetEditEventMode = () => {
	return {
		type: RESET_EDIT_EVENT_MODE
	}
}

export const searchEvents = (name) => {
	return {
		type: SEARCH_EVENTS,
		payload: name
	}
}

export const updateVitalNote = (vitalNote) => {
	return {
		type: UPDATE_DRAFT_VITAL_NOTE,
		payload: vitalNote
	}
}

export const resetError = () =>{
	return {
		type: RESET_ERROR
	}
}
export const resetSuccess =()=>{
	return {
		type: RESET_SUCCESS
	}
}