import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import {
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_RETURNED,
  NEW_PASSWORD_RETURNED,
  NEW_PASSWORD_SUBMIT,
} from "../constants/ActionTypes";
import { selectUser } from "../app/modules/user/state/action/usersActions";
import {
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess,
  setLoggedInUser,
  hideAuthLoader,
} from "../actions/Auth";
import {
  showErrorMessage,
  showSweetSuccessMessage,
} from "../actions/Notifications";
import { User } from "../app/modules/user/state/model/User";
import { BasicInfo } from "../app/modules/user/state/model/BasicInfo";
import { permissionConverter as psConverter } from "../app/core/utils/PermissionConverter";
import Auth from "../app/core/utils/amplify-setup";
import { account_roles } from "../app/modules/organization/state/model/organization";
import {
  getOrgsApiGWClient,
  getUsersApiGWClient,
} from "../app/core/utils/auth-functions";

const availableGroups = ["org-admins", "operators", "doctors", "users"];
const errorMessage = "Something went wrong. please try again later";

// todo: check
function* saveCache(userCache) {
  let error = false;
  let message = "";
  let body = convertObject(userCache);
  let pathTemplate = "/api/usercache";
  let method = "post";

  const apigClient = getOrgsApiGWClient(null, null, null);
  yield apigClient
    .invokeApi({}, pathTemplate, method, {}, body)
    .catch((err) => {
      error = true;
      message = err.message ? err.message : errorMessage;
    });

  return [error, message];
}

// todo: check m4 3arf a
function* createUserWithEmailPassword({ payload }) {
  const { firstName, lastName, email, password, phoneNumber } = payload;
  let msg = "";
  let error = false;

  try {
    const user = yield Auth.signUp({
      username: email.toLowerCase(),
      password,
      attributes: {
        name: firstName,
        family_name: lastName,
        phone_number: phoneNumber ? phoneNumber : "",
        "custom:role": "user",
        "custom:canAccess": "true",
        "custom:account_roles": JSON.stringify(
          new account_roles({ user: true })
        ),
      },
      validationData: [], //optional
    }).catch((err) => {
      error = true;
      msg = err.message ? err.message : errorMessage;
    });

    if (error) {
      yield put(hideAuthLoader());
      yield put(showErrorMessage(msg));
      return;
    }

    let body = convertObject({
      userId: user.userSub,
      groupName: "users",
      edit: false,
    });
    let pathTemplate = "/api/add-user-to-group";
    let method = "post";

    const apigClient = getUsersApiGWClient(null, null, null);
    yield apigClient
      .invokeApi({}, pathTemplate, method, {}, body)
      .then((response) => {
        if (!response.data.success) {
          error = true;
          msg = response.data.message ? response.data.message : errorMessage;
        }
      })
      .catch((err) => {
        error = true;
        msg = err.message ? err.message : errorMessage;
      });

    if (error) {
      yield put(hideAuthLoader());
      yield put(showErrorMessage(msg));
      return;
    }

    const userCache = {
      email,
      user_id: user.userSub,
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      can_access: true,
      role: "user",
      account_roles: new account_roles({ user: true }),
    };
    const [cacheError, cacheMessage] = yield call(saveCache, userCache);
    if (cacheError) {
      yield put(hideAuthLoader());
      yield put(showErrorMessage(cacheMessage));
      return;
    }
    yield put(userSignUpSuccess());
    yield put(showSweetSuccessMessage("messages.accountCreationSuccess"));
  } catch (err) {
    msg = err.message ? err.message : errorMessage;
    yield put(hideAuthLoader());
    yield put(showErrorMessage(msg));
  }
}

/** EVENTS FUNCTIONS **/
function convertObject(data) {
  let obj = JSON.parse(JSON.stringify(data));
  return obj;
}

// no edit need
function* signInUserWithEmailPassword({ payload }) {
  const { password } = payload;
  let msg = "";
  let error = false;

  let user = {};
  try {
    user = yield Auth.signIn(payload.email.toLowerCase(), password);
  } catch (err) {
    msg = err.message ? err.message : errorMessage;
    error = true;
  }

  if (!error) {
    let newUser = null;
    const { attributes, signInUserSession } = user;
    const accessToken = signInUserSession.accessToken;
    const { email, sub } = attributes;
    const firstName = attributes["name"];
    const lastName = attributes["family_name"];
    const phoneNumber = attributes["phone_number"];
    const role = attributes["custom:role"];
    const account_roles = attributes["custom:account_roles"];
    const basicInfo = new BasicInfo({
      firstName,
      lastName,
      email,
      phoneNumber,
    });
    const userGroups = accessToken.payload["cognito:groups"];
    let groupName = "";
    let permissions = [];
    if (userGroups.length === 1) {
      permissions = [...psConverter(userGroups[0])];
    } else {
      availableGroups.forEach((avGrp) => {
        if (userGroups.includes(avGrp)) {
          groupName += avGrp + "-";
        }
      });
      permissions = [...psConverter(groupName)];
    }
    const permissionsSet = new Set(permissions);
    const userPermissions = Array.from(permissionsSet);
    newUser = new User({
      id: sub,
      basicInfo,
      role,
      permissions: userPermissions,
      account_roles: JSON.parse(account_roles),
    });

    if (
      userGroups.includes("org-admins") ||
      userGroups.includes("doctors") ||
      userGroups.includes("operators")
    ) {
      const orgName = attributes["custom:orgName"];
      const orgId = attributes["custom:orgId"];
      newUser = { ...newUser, orgId, orgName };
    }

    yield localStorage.setItem("user_id", sub);
    yield localStorage.setItem("loggedInUser", JSON.stringify(newUser));
    yield localStorage.setItem("selectedUser", JSON.stringify(newUser));
    yield put(setLoggedInUser(newUser));
    yield put(selectUser(newUser));
    yield put(userSignInSuccess(sub));
  } else {
    yield put(hideAuthLoader());
    yield put(showErrorMessage(msg));
  }
}
// no edit need
export function* forgotPassword(action) {
  let error = false;
  let message = "";
  yield Auth.forgotPassword(action.payload)
    .then((_) => (message = "messages.forgetPassSuccess"))
    .catch((err) => {
      error = true;
      if (err.code === "UserNotFoundException") {
        message = "No account with this email exists.";
      } else {
        message = err.message;
      }
    });

  if (error) {
    yield put(showErrorMessage(message));
  } else {
    yield put(showSweetSuccessMessage(message));
  }

  yield put({
    type: FORGOT_PASSWORD_RETURNED,
  });
}
//  no edit need
export function* resetPassword({ payload }) {
  let error = false;
  let message = "";
  const { email, code, newPassword } = payload;

  yield Auth.forgotPasswordSubmit(email, code, newPassword)
    .then((_) => (message = "messages.resetPassSuccess"))
    .catch((err) => {
      error = true;
      message = err.message;
    });

  if (error) {
    yield put(showErrorMessage(message));
  } else {
    yield put(showSweetSuccessMessage(message));
  }
  yield put({
    type: NEW_PASSWORD_RETURNED,
  });
}

function* signOut() {
  try {
    let msg = "";
    let error = false;

    yield Auth.signOut().catch((err) => {
      error = true;
      msg = err.message ? err.message : errorMessage;
    });

    if (!error) {
      yield localStorage.removeItem("user_id");
      yield localStorage.removeItem("loggedInUser");
      yield put(userSignOutSuccess());
    } else {
      yield put(hideAuthLoader());
      yield put(showErrorMessage(msg));
    }
  } catch (error) {
    yield put(hideAuthLoader());
    yield put(showErrorMessage(error.message));
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(createUserAccount),
    fork(signOutUser),
    takeEvery(FORGOT_PASSWORD, forgotPassword),
    takeEvery(NEW_PASSWORD_SUBMIT, resetPassword),
  ]);
}
