import { all, takeEvery, put } from "redux-saga/effects";
import { showErrorMessage } from "../../../../../actions/Notifications";
import { GET_DAILY_CHAT_RESERVATIONS } from "../constants/actionTypes";
import {
  getDailyChatReservationsSuccess,
  getDailyChatReservationsFailed,
} from "../actions/adminActions";
import { orgsApiClient } from "../../../../core/utils/restClient";
const errorMessage = "Something went wrong, please try again later.";

export function* getDailyChatReservations(action) {
  try {
    const pathTemplate = "/api/get-chat-reservations";
    const myInit = {
      queryStringParameters: {
        ...action.payload,
      },
    };
    const res = yield orgsApiClient.get(pathTemplate, myInit);
    if (res.success) {
      return yield put(getDailyChatReservationsSuccess(res.payload));
    }
    yield put(getDailyChatReservationsFailed());
    yield put(showErrorMessage(res.message || errorMessage));
  } catch (e) {
    yield put(getDailyChatReservationsFailed());
    yield put(showErrorMessage(errorMessage));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(GET_DAILY_CHAT_RESERVATIONS, getDailyChatReservations)]);
}
