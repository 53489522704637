import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Stepper, Step, StepLabel, Button, CircularProgress, IconButton } from '@material-ui/core';
import AdminInfo from '../components/AdminInfo';
import OrgBasicInfo from '../components/OrgBasicInfo';
import OrgSummary from '../components/OrgSummary';
import IntlMessages from '../../../../../util/IntlMessages';
import {
	createOrganization,
	editOrg,
	updateOrgBasicInfo,
	updateAdminInfo,
	updateConfirmPassword,
	enableAdminCreate,
	checkTerms,
	updateAdminErrors,
	updateBasicErrors,
	getOrganizationTypes,
	getSpecialtiesTypes,
} from '../../state/actions/orgActions';

import {
	getCities,
	getCountries,
	getStates,
	changeCountryId,
	changeStateId
} from '../../../../../actions/countries';

import { resetSweetSuccess } from '../../../../../actions/Notifications';
import queryString from 'query-string';

class OrgSignUpStepper extends React.Component {
	state = {
		activeStep: 0,
		hideBackButton: false
	};

	componentDidMount() {
		let values = queryString.parse(this.props.location.search);
		if (values) {
			if (values.m === "1") {
				this.setState({ hideBackButton: true });
			}
		}
	}

	getSteps() {
		return [
			{ id: 0, label: <IntlMessages id="org.info" /> },
			{ id: 1, label: <IntlMessages id="org.adminInfo" /> },
			{ id: 2, label: <IntlMessages id="org.summary" /> }
		];
	}

	getStepContent(stepIndex) {
		switch (stepIndex) {
			case 0:
				return this.getBasicInfo();
			case 1:
				return this.getAdminInfo();
			case 2:
				return this.getSummary();
			default:
				return 'Uknown stepIndex';
		}
	}

	getBasicInfo() {
		return (
			<OrgBasicInfo
				lang={this.props.lang}
				width={this.props.width}
				edit={this.props.edit}
				adminCreate={this.props.adminCreate}
				orgAdminEdit={this.props.orgAdminEdit}
				basicInfo={this.props.basicInfo}
				onOrgBasicInfoUpdate={this.props.onOrgBasicInfoUpdate}
				onUpdateBasicErrors={this.props.onUpdateBasicErrors}
				getCountries={this.props.getCountries}
				getStates={this.props.getStates}
				getOrgTypes={this.props.getOrganizationTypes}
				organizationTypes={this.props.organizationTypes}
				organizationSpecialties={this.props.organizationSpecialties}
				getSpecialities={this.props.getSpecialtiesTypes}
				getCities={this.props.getCities}
				countries={this.props.countries}
				cities={this.props.cities}
				states={this.props.states}
				stateId={this.props.stateId}
				countryId={this.props.countryId}
				changeCountryId={this.props.changeCountryId}
				changeStateId={this.props.changeStateId}
			/>
		);
	}

	getAdminInfo() {
		return (
			<AdminInfo
				width={this.props.width}
				edit={this.props.edit}
				lang={this.props.lang}
				canEditAdminInfo={this.props.authUser && this.props.authUser.permissions.includes("CAN_EDIT_ADMIN_INFO")}
				confirmPassword={this.props.confirmPassword}
				adminCreate={this.props.adminCreate}
				orgAdminEdit={this.props.orgAdminEdit}
				adminInfo={this.props.adminInfo}
				onAdminInfoUpdate={this.props.onAdminInfoUpdate}
				onConfirmPasswordUpdate={this.props.onConfirmPasswordUpdate}
				onUpdateAdminErrors={this.props.onUpdateAdminErrors}
			/>
		);
	}

	getSummary() {
		return (
			<OrgSummary
				width={this.props.width}
				draftOrg={this.props.org}
				edit={this.props.edit}
				adminCreate={this.props.adminCreate}
				orgAdminEdit={this.props.orgAdminEdit}
				checkTerms={this.props.onCheckTerms}
				termsChecked={this.props.termsChecked}
			/>
		);
	}

	handleNext = () => {
		const { activeStep } = this.state;
		this.setState({
			activeStep: activeStep + 1
		});
	};

	handleBack = () => {
		const { activeStep } = this.state;
		this.setState({
			activeStep: activeStep - 1,
		});
	};

	handleReset = () => {
		this.setState({
			activeStep: 0,
		});
	};

	handleClick = (index) => {
		this.setState({
			activeStep: index,
		});
	}

	handleSave = () => {
		const { org, edit, adminCreate, authUser } = this.props;
		if (edit) {
			this.props.onEditOrganization(org, authUser);
		}
		else {
			this.props.onSaveOrganization(org, adminCreate, authUser);
		}
	}

	onConfirm = () => {
		this.props.resetSweetSuccess();
		if (!this.props.adminCreate) {
			this.props.history.push('/signin');
		}
		else {
			this.props.disableAdminCreate();
		}
	}

	render() {
		const steps = this.getSteps();
		const { activeStep } = this.state;
		const { loading, org, confirmPasswordError, alertMessage, sweetSuccess, isDirectionRTL,
			termsChecked, edit, allAdminErrors, allBasicErrors, adminCreate, orgAdminEdit } = this.props;
		let enabled, validAdminSignUpInfo, validBasicInfo, validAdminInfo = false;
		if (org) {
			const { basicInfo, adminInfo } = org;
			const { legalName, orgType, speciality, address1, city, province, country, phoneNumber } = basicInfo;
			const { firstName, lastName, password, confirmPassword } = adminInfo;
			validBasicInfo = legalName && orgType && speciality && phoneNumber && address1 && country && city && province;
			validAdminInfo = firstName && lastName;
			validAdminSignUpInfo = adminInfo.email && password && confirmPassword && !confirmPasswordError;
			enabled = validBasicInfo && validAdminInfo;
		}
		return (
			<div className="w-100">
				{(!adminCreate && !orgAdminEdit && !edit && !this.state.hideBackButton) &&
					<IconButton className="icon-btn" style={!isDirectionRTL ? { marginLeft: 8, marginTop: 8 } : { marginRight: 8, marginTop: 8 }}
						onClick={() => this.props.history.push('/signin')}>
						<i className={!isDirectionRTL ? "zmdi zmdi-arrow-left" : "zmdi zmdi-arrow-right"} />
					</IconButton>
				}
				<div className="module-detail module-list">
					<Stepper activeStep={activeStep} alternativeLabel className="horizontal-stepper-linear">
						{steps.map((item, index) => {
							return (
								<Step onClick={() => { this.handleClick(index) }} key={item.id}
									className={`horizontal-stepper ${index === activeStep ? 'active' : ''}`}>
									<StepLabel className="stepperlabel">{item.label}</StepLabel>
								</Step>
							);
						})}
					</Stepper>
					<div style={{ marginBottom: 20 }}>
						<div>
							{loading ?
								<div style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									height: edit || adminCreate ? 'calc(100vh - 360px)' : 'calc(100vh - 270px)'
								}}>
									<CircularProgress />
								</div>
								: this.getStepContent(activeStep)}
							<div style={{ textAlign: 'right', marginRight: 20 }}>
								<Button
									disabled={activeStep === 0}
									onClick={this.handleBack}
									className="mr-2"
									variant="contained"
								>
									<IntlMessages id="org.back" />
								</Button>
								<Button variant="contained" color="primary"
									onClick={activeStep === steps.length - 1 ? this.handleSave : this.handleNext}
									disabled={!edit ?
										((activeStep === steps.length - 1 && (!enabled || !validAdminSignUpInfo || !termsChecked))
											|| (activeStep === 0 && (!validBasicInfo || allBasicErrors))
											|| (activeStep === 1 && (!validAdminInfo || !validAdminSignUpInfo || allAdminErrors)))
										: (activeStep === steps.length - 1 && !enabled)}>
									{activeStep === steps.length - 1 ? <IntlMessages id="org.finish" /> : <IntlMessages id="org.next" />}
								</Button>
							</div>
						</div>
					</div>
				</div>
				<SweetAlert show={sweetSuccess} success title={<IntlMessages id={alertMessage} />} onConfirm={this.onConfirm} />
			</div >
		);
	}
}

const mapStateToProps = ({ settings, orgs, auth, notifications, countries }) => {
	return {
		width: settings.width,
		lang: settings.locale.locale,
		org: orgs.draftOrg ? orgs.draftOrg : {},
		basicInfo: orgs.draftOrg ? orgs.draftOrg.basicInfo : {},
		adminInfo: orgs.draftOrg ? orgs.draftOrg.adminInfo : {},
		confirmPasswordError: orgs.confirmPasswordError,
		confirmPassword: orgs.confirmPassword,
		termsChecked: orgs.termsChecked,
		authUser: auth.loggedInUser,
		allAdminErrors: orgs.allAdminErrors,
		allBasicErrors: orgs.allBasicErrors,
		loading: orgs.loader,
		isDirectionRTL: settings.isDirectionRTL,
		sweetSuccess: notifications.sweetSuccess,
		alertMessage: notifications.alertMessage,
		countries: countries.countries,
		countryId: countries.countryId,
		states: countries.states,
		cities: countries.cities,
		organizationTypes: orgs.organizationTypes,
		organizationSpecialties: orgs.organizationSpecialties,
		stateId: countries.stateId
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onOrgBasicInfoUpdate: (orgBasicInfo) => {
			dispatch(updateOrgBasicInfo(orgBasicInfo))
		},
		onAdminInfoUpdate: (adminInfo) => {
			dispatch(updateAdminInfo(adminInfo))
		},
		onUpdateAdminErrors: (errors) => {
			dispatch(updateAdminErrors(errors))
		},
		onUpdateBasicErrors: (errors) => {
			dispatch(updateBasicErrors(errors))
		},
		onConfirmPasswordUpdate: (confirmPassword) => {
			dispatch(updateConfirmPassword(confirmPassword))
		},
		onCheckTerms: (value) => {
			dispatch(checkTerms(value))
		},
		onSaveOrganization: (org, adminCreate, authUser) => {
			dispatch(createOrganization({ clinic: org, adminCreate, authUser }))
		},
		onEditOrganization: (org, loggedInUser) => {
			dispatch(editOrg({ clinic: org, loggedInUser }));
		},
		disableAdminCreate: () => {
			dispatch(enableAdminCreate(false));
		},
		getCountries: (id) => {
			dispatch(getCountries(id));
		},
		getStates: (id) => {
			dispatch(getStates(id));
		},
		getCities: (id) => {
			dispatch(getCities(id));
		},
		changeCountryId: (id) => {
			dispatch(changeCountryId(id));
		},
		changeStateId: (id) => {
			dispatch(changeStateId(id))
		},
		resetSweetSuccess: () => {
			dispatch(resetSweetSuccess())
		},
		getOrganizationTypes: () => {
			dispatch(getOrganizationTypes())
		},
		getSpecialtiesTypes: (orgId) => {
			dispatch(getSpecialtiesTypes(orgId))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrgSignUpStepper));