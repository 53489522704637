import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import Footer from 'components/Footer';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import { isIOS, isMobile } from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import TopNav from 'components/TopNav';
import { CYAN } from 'constants/ThemeColors';

class App extends React.Component {
  constructor(props) {
    super(props);
    const body = document.body.classList;
    body.add(CYAN);
  }

  render() {
    const { match, drawerType, navigationStyle, horizontalNavPosition } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height')
    }
    else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height')
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        <Sidebar />
        <div className="app-main-container">
          <div
            className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
              <TopNav styleName="app-top-header" />}
            <Header history={this.props.history} />
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
              <TopNav />}
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                <Route path={`${match.url}/clinic`}
                  component={asyncComponent(() => import('./modules/user/view/UserMainView'))} />

                <Route path={`${match.url}/my-health`}
                  component={asyncComponent(() => import('./modules/my-health/MyHealthMain'))} />

                <Route path={`${match.url}/adminpanel`}
                  component={asyncComponent(() => import('./modules/admin/AdminMainView'))} />

                <Route path={`${match.url}/doctorpanel`}
                  component={asyncComponent(() => import('./modules/doctor/DoctorMainView'))} />

                <Route path={`${match.url}/wallet`}
                  component={asyncComponent(() => import('./modules/wallet/view/WalletMainView'))} />

                <Route path={`${match.url}/reservations`}
                  component={asyncComponent(() => import('./modules/reservation/view/reservationMainView'))} />
                  
                <Route component={asyncComponent(() => import('components/Error404'))} />
              </Switch>
            </div>
            {window.innerWidth > 700 && <Footer />}
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, navigationStyle, horizontalNavPosition };
};

export default withRouter(connect(mapStateToProps)(App));