export class User {
	constructor({ id, basicInfo, isDeleted = false, role, orgId, orgName, permissions = [], account_roles }) {
		this.basicInfo = basicInfo;
		this.id = id;
		this.isDeleted = isDeleted;
		this.role = role;
		this.orgId = orgId;
		this.orgName = orgName;
		this.permissions = permissions;
		this.account_roles = account_roles;
	}
}