import {
  CHANGE_USERS_STATUS_REQUEST,
  GET_ADMIN_FILTERED_USERS,
  GET_ORG_USERS_REQUEST,
  GET_USER,
  RESET_USERS,
  UPDATE_ADMIN_FILTERS,
  UPDATE_FILTERS,
  USER_SELECTED,
  SEARCH_USERS
} from "./actionTypes";

export const selectUser = (user) => {
  return {
    type: USER_SELECTED,
    payload: user,
  };
};

export const getUser = (id) => {
  return {
    type: GET_USER,
    payload: {
      id: id,
    },
  };
};

export const searchUsers = (keyword) => {
  return {
    type: SEARCH_USERS,
    payload: keyword
  };
};

export const listenToUsers = (id) => {
  return {
    type: GET_ORG_USERS_REQUEST,
    payload: id,
  };
};

export const getFilteredUsers = (group) => {
  return {
    type: GET_ADMIN_FILTERED_USERS,
    payload: group,
  };
};

export const updateFilters = (id) => {
  return {
    type: UPDATE_FILTERS,
    payload: id,
  };
};

export const updateAdminFilters = (id) => {
  return {
    type: UPDATE_ADMIN_FILTERS,
    payload: id,
  };
};

export const changeUsersStatus = (obj) => {
  return {
    type: CHANGE_USERS_STATUS_REQUEST,
    payload: obj,
  };
};

export const resetUsers = () => {
  return {
    type: RESET_USERS,
  };
};
