import React from 'react';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import {
	SUMMARY_VITALS_REQUESTED,
	SUMMARY_VITALS_RETURNED
} from '../constants/ActionTypes';
import { showErrorMessage } from '../../../../../../actions/Notifications';
import IntlMessages from 'util/IntlMessages';
import { getTokens, getEventsApiGWClient } from '../../../../../core/utils/auth-functions';
import moment from 'moment';

export function* fetchData(action) {
	let error = false;
	let charts = [];
	let vitals = [];
	let message = '';
	const { userId, startDate, endDate, requesterId, orgId } = action.payload;
	var additionalParams = {
		queryParams: {
			data: JSON.stringify({ userId, startDate, endDate, requesterId, orgId })
		},
	};
	var pathTemplate = '/api/summary';
	var method = 'GET';

	const tokens = yield call(getTokens);
	if (!tokens[3]) {
		const apigClient = getEventsApiGWClient(tokens[0], tokens[1], tokens[2]);
		yield apigClient.invokeApi({}, pathTemplate, method, additionalParams, {})
			.then(response => {
				if (response.data.success) {
					vitals = response.data.payload;
				} else {
					message = response.data.message? response.data.message: "Something went wrong.";
					error = true;
				}
			})
			.catch(_ => {
				message = "Something went wrong."
				error = true;
			});
	} else {
		message = "Something went wrong."
		error = true;
	}
	if (!error) {
		let arrayVitals = [];
		for (var i = 0; i < vitals.length; i++) {
			const current = vitals[i];
			if (i === vitals.length - 1 || current.vital_id !== vitals[i + 1].vital_id) {
				arrayVitals.push({
					Time: moment(current.created_at).format('DD MMMM YYYY, LT'),
					[current.name]: current.value ? parseFloat(current.value, 10) : null
				});
				charts.push({ data: arrayVitals, label: current.name, title: <IntlMessages id={current.local_label} /> })
				arrayVitals = [];
			} else {
				arrayVitals.push({
					Time: moment(current.created_at).format('DD MMMM YYYY, LT'),
					[current.name]: current.value ? parseFloat(current.value, 10) : null
				});
			}
		}
	}
	else {
		yield put(showErrorMessage(message));
	}

	yield put({
		type: SUMMARY_VITALS_RETURNED,
		payload: {
			charts,
			error
		}
	})
}

export default function* rootSaga() {
	yield all([
		yield takeEvery(SUMMARY_VITALS_REQUESTED, fetchData)
	]);
}