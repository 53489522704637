// ROLES PERMISSIONS
const IS_A_DOCTOR = "IS_A_DOCTOR";
const IS_A_USER = "IS_A_USER";
const IS_AN_ORG_ADMIN = "IS_AN_ORG_ADMIN";
const IS_AN_OPERATOR = "IS_AN_OPERATOR";
const IS_A_CALLVITA_ADMIN = "IS_A_CALLVITA_ADMIN";

//VIEWS_PERMISSIONS
const VIEW_OWN_EVENTS = "VIEW_OWN_EVENTS";
const VIEW_OWN_SUMMARY = "VIEW_OWN_SUMMARY";
const VIEW_OWN_CLINICS = "VIEW_OWN_CLINICS";
const VIEW_DETAILED_ORGS = "VIEW_DETAILED_ORGS";
const VIEW_DETAILED_USERS = "VIEW_DETAILED_USERS";
const VIEW_ORG_DETAILED_USERS = "VIEW_ORG_DETAILED_USERS";
const VIEW_ALL_DETAILED_USERS = "VIEW_ALL_DETAILED_USERS";
const VIEW_OWN_PATIENT_QUEUE = "VIEW_OWN_PATIENTS_QUEUE";
const VIEW_PROVIDERS_CALENDAR = "VIEW_PROVIDERS_CALENDAR";
const VIEW_CLINIC_PROFILE = "VIEW_CLINIC_PROFILE";
const VIEW_RESERVATIONS = "VIEW_RESERVATIONS";
const VIEW_OWN_USER_RESERVATIONS = "VIEW_OWN_USER_RESERVATIONS";
const FIND_A_DOCTOR = "FIND_A_DOCTOR";
const VIEW_DR_RESERVATIONS = "VIEW_DR_RESERVATIONS";
const VIEW_ALL_RESERVATIONS = "VIEW_ALL_RESERVATIONS";
const VIEW_MY_PROVIDER_CALENDAR = "VIEW_MY_PROVIDER_CALENDAR";
const VIEW_ALL_PROVIDERS_CALENDARS = "VIEW_ALL_PROVIDERS_CALENDARS";
const VIEW_ALL_QUEUE = "VIEW_ALL_QUEUE";
const VIEW_MYSELF_QUEUE = "VIEW_MYSELF_QUEUE";
const GENERATE_LINK = "GENERATE_LINK";
const VIEW_ADMIN_STATISTICS = "VIEW_ADMIN_STATISTICS";
const VIEW_TRANSACTIONS_STATISTICS = "VIEW_TRANSACTIONS_STATISTICS";
const VIEW_CALLVITA_FEES = "VIEW_CALLVITA_FEES";
const VIEW_WALLET_NAVBAR = "VIEW_WALLET_NAVBAR";
const SEND_NOTIFICATION = "SEND_NOTIFICATION"

//EVENTS_PERMISSIONS
const EVENTS_READ = "EVENTS_READ";
const EVENTS_WRITE = "EVENTS_WRITE";
const SUMMARY_READ = "SUMMARY_READ";

//USERS_PERMISSIONS
const ADD_USER = "ADD_USER";
const USERS_READ = "USERS_READ";
const USERS_QUEUE_READ = "USERS_QUEUE_READ";
const CAN_ADD_USER_TO_QUEUE = "CAN_ADD_USER_TO_QUEUE";
const CAN_EDIT_ROLE = "CAN_EDIT_ROLE";
const CAN_ADD_DR = "CAN_ADD_DR";
const CAN_ADD_PATIENT = "CAN_ADD_PATIENT";
const CAN_ADD_OP = "CAN_ADD_OP";
const CAN_RESERVE_FOR_USER = "CAN_RESERVE_FOR_USER";
const CAN_EDIT_ADMIN_INFO = "CAN_EDIT_ADMIN_INFO";

export const permissionConverter = (group) => {
  let permissions = [];
  switch (group) {
    case 'users':
      permissions = [IS_A_USER, VIEW_OWN_EVENTS, EVENTS_READ, VIEW_OWN_SUMMARY, VIEW_OWN_CLINICS, EVENTS_WRITE, SUMMARY_READ, FIND_A_DOCTOR, VIEW_OWN_USER_RESERVATIONS];
      break;

    case 'org-admins':
    case 'org-admins-':
      permissions = [IS_AN_ORG_ADMIN, CAN_EDIT_ADMIN_INFO, VIEW_DETAILED_USERS, VIEW_ORG_DETAILED_USERS, VIEW_ALL_PROVIDERS_CALENDARS, VIEW_PROVIDERS_CALENDAR, USERS_READ, ADD_USER, CAN_EDIT_ROLE, CAN_ADD_DR, CAN_ADD_PATIENT, CAN_ADD_OP, VIEW_CLINIC_PROFILE, VIEW_WALLET_NAVBAR];
      break;

    case 'callvita-admins':
      permissions = [IS_A_CALLVITA_ADMIN, CAN_EDIT_ADMIN_INFO, VIEW_DETAILED_ORGS, VIEW_DETAILED_USERS, VIEW_ALL_DETAILED_USERS, VIEW_ADMIN_STATISTICS, VIEW_TRANSACTIONS_STATISTICS, VIEW_CALLVITA_FEES,SEND_NOTIFICATION];
      break;

    case 'operators':
    case 'operators-':
      permissions = [IS_AN_OPERATOR, VIEW_ALL_QUEUE, VIEW_ALL_RESERVATIONS, VIEW_ALL_PROVIDERS_CALENDARS, VIEW_DETAILED_USERS, VIEW_PROVIDERS_CALENDAR, VIEW_ORG_DETAILED_USERS, USERS_READ, ADD_USER, USERS_QUEUE_READ, EVENTS_READ, SUMMARY_READ, CAN_ADD_USER_TO_QUEUE, CAN_ADD_PATIENT, CAN_RESERVE_FOR_USER, VIEW_RESERVATIONS];
      break;

    case 'doctors':
    case 'doctors-':
      permissions = [IS_A_DOCTOR, GENERATE_LINK, VIEW_MYSELF_QUEUE, VIEW_MY_PROVIDER_CALENDAR, VIEW_DR_RESERVATIONS, VIEW_OWN_PATIENT_QUEUE, VIEW_RESERVATIONS, VIEW_DETAILED_USERS, VIEW_PROVIDERS_CALENDAR, VIEW_ORG_DETAILED_USERS, SUMMARY_READ, EVENTS_READ, CAN_ADD_USER_TO_QUEUE, CAN_ADD_PATIENT, CAN_ADD_OP, CAN_RESERVE_FOR_USER, USERS_QUEUE_READ, VIEW_CLINIC_PROFILE];
      break;

    case 'org-admins-operators-':
      permissions = [
        IS_AN_OPERATOR,
        VIEW_DETAILED_USERS,
        VIEW_PROVIDERS_CALENDAR,
        VIEW_ORG_DETAILED_USERS,
        USERS_QUEUE_READ,
        EVENTS_READ,
        SUMMARY_READ,
        CAN_ADD_USER_TO_QUEUE,
        CAN_RESERVE_FOR_USER,
        VIEW_RESERVATIONS,
        VIEW_ALL_PROVIDERS_CALENDARS,
        VIEW_ALL_RESERVATIONS,
        VIEW_ALL_QUEUE,

        IS_AN_ORG_ADMIN,
        CAN_EDIT_ADMIN_INFO,
        VIEW_ORG_DETAILED_USERS,
        VIEW_PROVIDERS_CALENDAR,
        USERS_READ,
        ADD_USER,
        CAN_EDIT_ROLE,
        CAN_ADD_DR,
        CAN_ADD_PATIENT,
        CAN_ADD_OP,
        VIEW_CLINIC_PROFILE
      ]
      break;

    case 'org-admins-doctors-':
      permissions = [
        IS_AN_ORG_ADMIN,
        CAN_EDIT_ADMIN_INFO,
        GENERATE_LINK,
        VIEW_ORG_DETAILED_USERS,
        VIEW_ALL_PROVIDERS_CALENDARS,
        USERS_READ,
        ADD_USER,
        CAN_EDIT_ROLE,
        CAN_ADD_DR,
        CAN_ADD_PATIENT,
        CAN_ADD_OP,

        IS_A_DOCTOR,
        VIEW_MYSELF_QUEUE,
        VIEW_DR_RESERVATIONS,
        VIEW_OWN_PATIENT_QUEUE,
        VIEW_RESERVATIONS,
        VIEW_DETAILED_USERS,
        VIEW_PROVIDERS_CALENDAR,
        SUMMARY_READ,
        EVENTS_READ,
        CAN_ADD_USER_TO_QUEUE,
        CAN_RESERVE_FOR_USER,
        USERS_QUEUE_READ,
        VIEW_CLINIC_PROFILE,
        VIEW_WALLET_NAVBAR
      ]
      break;

    case 'org-admins-operators-doctors-':
      permissions = [
        IS_AN_OPERATOR,
        CAN_EDIT_ADMIN_INFO,
        USERS_READ,
        GENERATE_LINK,
        ADD_USER,
        CAN_ADD_USER_TO_QUEUE,
        CAN_RESERVE_FOR_USER,
        VIEW_RESERVATIONS,
        VIEW_ALL_QUEUE,

        IS_AN_ORG_ADMIN,
        CAN_EDIT_ROLE,
        CAN_ADD_DR,
        CAN_ADD_PATIENT,
        CAN_ADD_OP,
        VIEW_CLINIC_PROFILE,
        VIEW_ALL_PROVIDERS_CALENDARS,
        VIEW_ALL_RESERVATIONS,
        EVENTS_READ,
        SUMMARY_READ,

        IS_A_DOCTOR,
        VIEW_OWN_PATIENT_QUEUE,
        VIEW_DETAILED_USERS,
        VIEW_PROVIDERS_CALENDAR,
        VIEW_ORG_DETAILED_USERS,
        CAN_RESERVE_FOR_USER,
        USERS_QUEUE_READ,
        VIEW_CLINIC_PROFILE
      ]
      break;

    default:
      permissions = [];
      break;
  }

  return permissions;
}