import {
	SAVE_USER,
	SAVE_USER_SUCCESS,
	UPDATE_BASIC_INFO,
	USER_EDIT_MODE,
	GET_USER_EDIT,
	GET_USER_EDIT_RETURNED,
	OPEN_BASIC_INFO_DIALOG,
	UPDATE_USER_EDIT,
	DELETE_USER_REQUEST,
	SET_DELETE_MODE,
	ADD_USERS_TO_QUEUE_REQUESTED,
	SET_SELECT_QUEUE_MODE,
	SET_DR_ID,
	GET_DR_DATA_REQUESTED,
	RESET_DELETE_ENABLE,
	RESET_QUEUE_ENABLE,
	RESET_DR_ID_APPS_MODAL,
	QUEUE_USERS_REQUESTED,
	DELETE_QUEUE_USER_REQUESTED,
	SET_DR_ID_APPS_MODAL,
	RESET_ERROR,
	SEARCH,
	RESET_SUCCESS,
	CHECKED,
	SET_SELECT_RESERVE_MODE,
	RESET_RESERVE,
	RESERVE_TIME,
	RESERVE_OPEN,
	SET_RESERVE_DR_ID,
	SLOT_SELECTED,
	FINAL_RESERVE,
	RESET_RESERVE_DRAWER,
	UPDATE_BASIC_INFO_ERRORS,
	RESET_TIME_DURATION
} from './actionTypes';

export const saveUser = (user) => {
	return {
		type: SAVE_USER,
		payload: user
	}
}

export const setDeleteMode = (id) => {
	return {
		type: SET_DELETE_MODE,
		payload: id
	}
}

export const reserveOpen = () => {
	return {
		type: RESERVE_OPEN
	}
}

export const finalReserve = (selectedEndtime, selectedTime, selectedDrReserveId,
	loggedInUserId, selectedUserName, date, userOrgId, title, description,local_date, userSelectedForReserve) => {
	return {
		type: FINAL_RESERVE,
		payload: {
			selectedEndtime,
			selectedTime,
			selectedDrReserveId,
			loggedInUserId,
			selectedUserName,
			date,
			userOrgId,
			title,
			description,
			local_date,
			userSelectedForReserve
		}
	}
}

export const slotSelected = (time1, duration, drSelectedName) => {
	return {
		type: SLOT_SELECTED,
		payload: {
			time: time1,
			duration: duration,
			drSelectedName: drSelectedName
		}
	}
}

export const reservetime = (date, selectedDrReserveId, queueDrs, drReserve, userOrgId, reserveUserId) => {
	return {
		type: RESERVE_TIME,
		payload: {
			date: date,
			selectedDrReserveId: selectedDrReserveId,
			queueDrs: queueDrs,
			drReserve: drReserve,
			userOrgId: userOrgId,
			selectedUserName: reserveUserId
		}
	}
}

export const deleteUser = (id) => {
	return {
		type: DELETE_USER_REQUEST,
		payload: id
	}
}

export const saveUserSuccess = user => {
	return {
		type: SAVE_USER_SUCCESS,
		payload: user
	}
}

export const updateBasicInfo = basicInfo => {
	return {
		type: UPDATE_BASIC_INFO,
		payload: basicInfo
	}
}

export const updateBasicInfoErrors = errors => {
	return {
		type: UPDATE_BASIC_INFO_ERRORS,
		payload: errors
	}
}

export const setUserModeToEdit = isEdit => {
	return {
		type: USER_EDIT_MODE,
		payload: {
			isEdit: isEdit
		}
	}
}

export const getUserForEdit = (id) => {
	return {
		type: GET_USER_EDIT,
		payload: id
	}
}

export const updateUserEdit = (user) => {
	return {
		type: UPDATE_USER_EDIT,
		payload: user
	}
}

export const resetDraftUser = () => {
	return {
		type: GET_USER_EDIT_RETURNED,
		payload: { newUser: null }
	}
}

export const openBasicInfoDialog = (isOpen) => {
	return {
		type: OPEN_BASIC_INFO_DIALOG,
		payload: isOpen
	}
}

export const searchContactsActions = (results) => {
	return {
		type: SEARCH,
		payload: results
	}
}

export const addQueueUsers = (payload) => {
	return {
		type: ADD_USERS_TO_QUEUE_REQUESTED,
		payload
	}
}

export const setQueueMode = (id) => {
	return {
		type: SET_SELECT_QUEUE_MODE,
		payload: id
	}
}

export const setReserveMode = (id, name, item) => {
	return {
		type: SET_SELECT_RESERVE_MODE,
		payload: {
			id,
			name,
			item
		}
	}
}

export const setDrId = (id) => {
	return {
		type: SET_DR_ID,
		payload: id
	}
}

export const setReserveDrId = (name, id) => {
	return {
		type: SET_RESERVE_DR_ID,
		payload: { id: id, name: name }
	}
}

export const getDrs = (id) => {
	return {
		type: GET_DR_DATA_REQUESTED,
		payload: id
	}
}

export const resetDelete = () => {
	return {
		type: RESET_DELETE_ENABLE
	}
}

export const resetQueue = () => {
	return {
		type: RESET_QUEUE_ENABLE
	}
}

export const resetReserve = () => {
	return {
		type: RESET_RESERVE
	}
}

export const resetReserveDrawer = () => {
	return {
		type: RESET_RESERVE_DRAWER
	}
}

export const resetTD = () =>{
	return {
		type: RESET_TIME_DURATION
	}
}

export const resetDRIDAppsModal = () => {
	return {
		type: RESET_DR_ID_APPS_MODAL
	}
}

export const queueUsersRequested = (data) => {
	return {
		type: QUEUE_USERS_REQUESTED,
		payload: data
	}
}

export const deleteQueueUser = (data) => {
	return {
		type: DELETE_QUEUE_USER_REQUESTED,
		payload: data
	}
}

export const setDrIdAppsModal = (id) => {
	return {
		type: SET_DR_ID_APPS_MODAL,
		payload: id
	}
}

export const resetError = () => {
	return {
		type: RESET_ERROR
	}
}

export const resetSuccess = () => {
	return {
		type: RESET_SUCCESS
	}
}

export const checkedAction = (checked) => {
	return {
		type: CHECKED,
		payload: checked
	}
}

