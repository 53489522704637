import { CommentListItem } from '../models/Comments';

export const commentToCommentListItemConverter = comment => {
    const { id, createdByName, created_at, text } = comment;
    return new CommentListItem({
        id,
        createdByName, 
        createdAt: created_at, 
        text,
        model: comment
    });
}