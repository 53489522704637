import moment from 'moment';
import Preconditions from '../../../../../core/utils/Preconditions';

export class Event {
	constructor({
		id,
		title,
		description = '',
		createdAt = moment.utc().format(),
		createdBy,
		userId,
		type = '',
		eventDate = moment().locale('en').format(),
	}) {
		Preconditions.shouldBeDefined(title, "title must be defined");
		Preconditions.shouldBeDefined(createdBy, "created By must be defined");
		Preconditions.shouldBeDefined(createdAt, "created At must be defined");
		Preconditions.shouldBeDefined(userId, "user Id must be defined");
		this.id = id;
		this.title = title;
		this.description = description;
		this.createdAt = createdAt;
		this.userId = userId;
		this.createdBy = createdBy;
		this.type = type;
		this.eventDate = eventDate;
	}
}