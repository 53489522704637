import React from 'react';
import { ListItem } from './../../../../../core/model/ListItem';
import IntlMessages from '../../../../../../util/IntlMessages';

export const eventToListItemConverter = event => {
	const tags = [
		{
			label: "appModule.description",
			value: event.description
		},
		{
			label: "event.type",
			value: <IntlMessages id={`event.${event.type}`} />
		}
	]
	return new ListItem({
		id: event.id,
		name: event.title,
		createdAt: event.created_at,
		createdByName: event.createdByName,
		notes: event.notes,
		selected: false,
		tags,
		model: event
	});
}