import {
  GET_DAILY_RESERVATIONS,
  GET_DAILY_RESERVATIONS_SUCCESS,
  GET_DAILY_RESERVATIONS_FAILED,
} from "../constants/actionTypes";

const initialState = {
  reservations: [],
  reservationsLoading: false,
  tableFields: [
    {
      label: 'daily_reservations_activity.table.user_name'
    },
    {
      label: 'daily_reservations_activity.table.user_phone_number'
    },
    {
      label: 'daily_reservations_activity.table.user_email'
    },
    {
      label: 'daily_reservations_activity.table.provider_name'
    },
    {
      label: 'daily_reservations_activity.table.provider_phone_number'
    },
    {
      label: 'daily_reservations_activity.table.organization_address'
    },
    {
      label: 'daily_reservations_activity.table.reservation_type'
    },
    {
      label: 'daily_reservations_activity.table.reservation_status'
    },
    {
      label: 'daily_reservations_activity.table.payment_status'
    },
    {
      label: 'daily_reservations_activity.table.reservation_date'
    }
  ],
}

export const dailyReservations = (state = initialState, action) => {
  switch (action.type) {
    case GET_DAILY_RESERVATIONS:
      return {
        ...state,
        reservationsLoading: true
      };

    case GET_DAILY_RESERVATIONS_SUCCESS:
      return {
        ...state,
        reservationsLoading: false,
        reservations: action.payload
      };

    case GET_DAILY_RESERVATIONS_FAILED:
      return {
        ...state,
        reservationsLoading: false
      };

    default:
      return state;
  }
}