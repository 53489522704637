import {
  ADD_FUNDS_TO_USER,
  ADD_WALLET,
  ADD_WALLET_TO_USER,
  ADD_WALLET_TO_USER_RETURNED,
  ADMIN_ADD_FUND,
  ADMIN_ADD_FUND_RETURNED,
  CHANGE_WALLET_STATUS,
  CHANGE_WALLET_STATUS_RETURNED,
  GET_ORG_WALLET_TRANSACTIONS,
  GET_USERS,
  GET_USERS_RETURNED,
  GET_WALLET,
  GET_WALLET_FAILED,
  GET_WALLET_RETURNED,
  GET_WALLET_TRANSACTIONS,
  GET_WALLET_TRANSACTIONS_RETURNED,
  MY_WALLET,
  SEARCH_USERS,
  SEARCH_USERS_RETURNED,
} from "../action/actionTypes";
import { all, put, takeEvery } from "redux-saga/effects";
import {
  coreApiClient,
  usersApiClient,
} from "../../../../core/utils/restClient";
import { getMyWallet, getWallet } from "../action/walletActions";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../actions/Notifications";
import { getUsers as getUsersAction } from "../action/walletActions";
import { polishUsers } from "../../../user/state/service/saveUserSaga";
const errorMessage = "Something went wrong. please try again later.";

export function* getWalletByOwnerId(action) {
  const { ownerId } = action.payload;
  if (!ownerId) return;
  const pathTemplate = "/api/wallet/org-wallet";
  const myInit = {
    queryStringParameters: { ownerId },
  };
  try {
    const res = yield coreApiClient.get(pathTemplate, myInit);
    if (res.success) {
      const wallet = res.payload;
      return yield put({
        type: GET_WALLET_RETURNED,
        payload: wallet,
      });
    }
    yield put({ type: GET_WALLET_FAILED });
  } catch (error) {
    yield put({ type: GET_WALLET_FAILED });
  }
}

export function* getOrgWallet(action) {
  const { ownerId } = action.payload;
  const pathTemplate = "/api/wallet/my-wallet";
  const myInit = {
    queryStringParameters: { ownerId },
  };
  const _errorHandler = function* (msg) {
    yield put(showErrorMessage(msg));
    yield put({
      type: GET_WALLET_FAILED,
    });
  };
  try {
    const res = yield coreApiClient.get(pathTemplate, myInit);
    if (res.success) {
      const wallet = res.payload;
      return yield put({
        type: GET_WALLET_RETURNED,
        payload: wallet,
      });
    }
    _errorHandler(res.message || errorMessage);
  } catch (error) {
    _errorHandler(errorMessage);
  }

}
/**
 * @param action.payload: { ownerId }
 */
export function* addWallet(action) {
  const { ownerId } = action.payload;
  const pathTemplate = "/api/wallet/org-wallet";
  const myInit = {
    body: action.payload,
  };
  try {
    const res = yield coreApiClient.post(pathTemplate, myInit);
    if (res.success) {
      return yield put(getWallet({ ownerId }));
    }
    yield put(showErrorMessage(res.message || errorMessage));
  } catch (error) {
    yield put(showErrorMessage(errorMessage));
  }
}

/**
 * @param
 *  action.payload = {
 *    createdById,
 *    depositBy,
 *    value,
 *    walletId,
 *    depositMethod,
 *    chequeNumber,
 *    referenceNumber,
 *  }
 */
export function* adminAddsFunds(action) {
  const pathTemplate = "/api/wallet/org-wallet-transaction";
  const myInit = { body: action.payload };
  try {
    const res = yield coreApiClient.post(pathTemplate, myInit);
    if (res.success) {
      yield put({
        type: ADMIN_ADD_FUND_RETURNED,
      });
      return yield put(showSuccessMessage(res.message));
    }
    yield put(showErrorMessage(res.message || errorMessage));
  } catch (error) {
    yield put(showErrorMessage(errorMessage));
  }
}

export function* getWalletTransactions() {
  const pathTemplate = "/api/wallet/all-deposit-transactions";
  const myInit = {};
  try {
    const res = yield coreApiClient.get(pathTemplate, myInit);
    if (res.success) {
      const transactionsRetrieved = res.payload;
      return yield put({
        type: GET_WALLET_TRANSACTIONS_RETURNED,
        payload: transactionsRetrieved,
      });
    }
    yield put(showErrorMessage(res.message || errorMessage));
  } catch (error) {
    yield put(showErrorMessage(errorMessage));
  }
}

export function* getOrgWalletTransactions(action) {
  const pathTemplate = "/api/wallet/my-wallet-transactions";
  const myInit = {
    queryStringParameters: action.payload,
  };
  try {
    const res = yield coreApiClient.get(pathTemplate, myInit);
    if (res.success) {
      const walletTransactions = res.payload;
      return yield put({
        type: GET_WALLET_TRANSACTIONS_RETURNED,
        payload: walletTransactions,
      });
    }
    yield put(showErrorMessage(res.message || errorMessage));
  } catch (error) {
    yield put(showErrorMessage(errorMessage));
  }
}

export function* addWalletToUser(action) {
  const pathTemplate = "/api/wallet/user-wallet-mapping";
  const myInit = {
    body: action.payload,
  };
  try {
    const res = yield coreApiClient.post(pathTemplate, myInit);
    if (res.success) {
      yield put({
        type: ADD_WALLET_TO_USER_RETURNED,
      });
      return yield put(showSuccessMessage(res.message));
    }
    yield put(showErrorMessage(res.message || errorMessage));
  } catch (error) {
    yield put(showErrorMessage(errorMessage));
  }
}

export function* getUsers(action) {
  const pathTemplate = "/api/wallet/user-wallet-mapping";
  const myInit = {
    queryStringParameters: {
      walletId: action.payload,
    },
  };
  try {
    const res = yield coreApiClient.get(pathTemplate, myInit);
    if (res.success) {
      return yield put({
        type: GET_USERS_RETURNED,
        payload: res.payload,
      });
    }
    yield put(showErrorMessage(res.message || errorMessage));
  } catch (error) {
    yield put(showErrorMessage(errorMessage));
  }
}

export function* searchUsers(action) {
  const pathTemplate = "/api/search";
  const myInit = {
    queryStringParameters: {
      data: JSON.stringify({
        name: action.payload,
      }),
    },
  };
  try {
    const res = yield usersApiClient.get(pathTemplate, myInit);
    if (res.success) {
      const usersReturned = res.payload;
      const finalUsers = polishUsers(usersReturned, true);
      return yield put({
        type: SEARCH_USERS_RETURNED,
        payload: finalUsers,
      });
    }
    yield put(showErrorMessage(res.message || errorMessage));
  } catch (error) {
    yield put(showErrorMessage(errorMessage));
  }
}

export function* addFundsToUser(action) {
  const pathTemplate = "/api/wallet/user-wallet-transaction";
  const myInit = {
    body: action.payload,
  };
  try {
    const res = yield coreApiClient.post(pathTemplate, myInit);
    if (res.success) {
      yield put(getMyWallet({ ownerId: action.payload.ownerId }));
      yield put(getUsersAction(action.payload.fromWalletId));
      return yield put(showSuccessMessage(res.message));
    }
    yield put(showErrorMessage(res.message || errorMessage));
  } catch (error) {
    yield put(showErrorMessage(errorMessage));
  }
}

export function* changeWalletStatus(action) {
  const pathTemplate = "/api/wallet/user-wallet-mapping";
  const myInit = {
    body: action.payload,
  };
  try {
    const res = yield coreApiClient.put(pathTemplate, myInit);
    if (res.success) {
      yield put({
        type: CHANGE_WALLET_STATUS_RETURNED,
      });
      return yield put(showSuccessMessage(res.message));
    }
    yield put(showErrorMessage(res.message || errorMessage));
  } catch (error) {
    yield put(showErrorMessage(errorMessage));
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_WALLET, getWalletByOwnerId),
    yield takeEvery(MY_WALLET, getOrgWallet),
    yield takeEvery(ADMIN_ADD_FUND, adminAddsFunds),
    yield takeEvery(ADD_WALLET, addWallet),
    yield takeEvery(GET_WALLET_TRANSACTIONS, getWalletTransactions),
    yield takeEvery(ADD_WALLET_TO_USER, addWalletToUser),
    yield takeEvery(GET_USERS, getUsers),
    yield takeEvery(SEARCH_USERS, searchUsers),
    yield takeEvery(ADD_FUNDS_TO_USER, addFundsToUser),
    yield takeEvery(GET_ORG_WALLET_TRANSACTIONS, getOrgWalletTransactions),
    yield takeEvery(CHANGE_WALLET_STATUS, changeWalletStatus),
  ]);
}
