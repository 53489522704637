export class basicInfo {
  constructor({
    legalName,
    orgId,
    //alone
    status,
    healthId,
    clinicType,
    //address array
    address1,
    address2,
    city,
    province,
    postalCode,
    //point of contact array
    fullName,
    phoneNumber,
    email
  }
  ) {
    this.legalName = legalName;
    this.orgId = orgId;
    this.status = status;
    this.healthId = healthId;
    this.clinicType = clinicType;
    this.address1 = address1;
    this.address2 = address2;
    this.city = city;
    this.province = province;
    this.postalCode = postalCode;
    this.fullName = fullName;
    this.phoneNumber = phoneNumber;
    this.email = email;
  }
}

export class adminInfo {
  constructor({
    firstName,
    lastName,
    phoneNumber,
    email,
    password = "password",
    isDeleted,
    orgId
  }) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.phoneNumber = phoneNumber;
    this.email = email;
    this.isDeleted = isDeleted;
    this.orgId = orgId;
    this.password = password;
  }
}

export class organization {
  constructor({
    basicInfo,
    adminInfo,
  }) {
    this.basicInfo = basicInfo;
    this.adminInfo = adminInfo;
  }
}

export class account_roles {
  constructor({
    orgAdmin = false, doctor = false, operator = false, user = false
  }) {
    this.user = user;
    this.doctor = doctor;
    this.operator = operator;
    this.orgAdmin = orgAdmin;
  }
}