import {
  QUEUE_USERS_REQUESTED,
  QUEUE_USERS_RETURNED,
  DELETE_QUEUE_USER_RETURNED
} from '../../../user/state/action/actionTypes';
import { SIGNOUT_USER_SUCCESS } from '../../../../../constants/ActionTypes';

const initialState = {
  queueUsers: [],
  itemOptions: [
    {
      label: 'user.profile',
      id: 'PROFILE'
    },
    {
      label: 'user.healthEvents',
      id: 'EVENTS'
    },
    {
      label: 'user.healthSummary',
      id: 'SUMMARY'
    },
    {
      label: 'user.remove',
      id: 'REMOVE'
    }
  ],
  loader: false
}

export const doctor = (state = initialState, action) => {
  switch (action.type) {
    case QUEUE_USERS_REQUESTED:
      return {
        ...state,
        loader: true
      }

    case QUEUE_USERS_RETURNED:
      if (!action.payload.error) {
        return {
          ...state,
          queueUsers: action.payload.queueUsers,
          loader: false
        }
      } else {
        return {
          ...state,
          loader: false
        }
      }

    case DELETE_QUEUE_USER_RETURNED:
      if (!action.payload.error) {
        const deletedQueueUser = action.payload.userId;
        const newQueueusersAfterDelete = state.queueUsers.filter(user => {
          return user.id !== deletedQueueUser;
        })
        return {
          ...state,
          queueUsers: newQueueusersAfterDelete
        }
      } else {
        return {
          ...state
        }
      }
  
    
    case SIGNOUT_USER_SUCCESS:
      return initialState;

    default:
      return { ...state };
  }
}
