import {
  TOTAL_STATS_RETURNED,
  GET_TOTAL_STATS,
  GET_ORGS_ACTIVITY,
  ORGS_AVTIVITY_RETURNED,
  GET_CALLVITA_FEES_REQUEST,
  GET_CALLVITA_FEES_SUCCESS,
  GET_CALLVITA_FEES_FAILED,
  SET_EDIT_MODAL,
  SET_ADD_MODAL,
  GET_CURRENCY_SUCCESS,
  CREATE_CALLVITA_FEES_SUCCESS,
  EDIT_CALLVITA_FEES_SUCCESS,
  RESET_EDIT_ERROR,
  EDIT_CALLVITA_FEES_FAILED,
  DELETE_CALLVITA_FEES_SUCCESS,
  SEND_NOTIFICATION,
} from "../constants/actionTypes";

const initialState = {
  totalStatsLoading: false,
  editModalBoolean: false,
  addModalBoolean: false,
  notification: {},
  totalStats: [
    {
      totalCount: "...",
      label: "statistics.orgs",
      iconName: "assignment_turned_in",
    },
    {
      totalCount: "...",
      label: "statistics.reservations",
      iconName: "assignment",
    },
    {
      totalCount: "...",
      label: "statistics.users",
      iconName: "assignment_ind",
    },
    {
      totalCount: "...",
      label: "statistics.active_orgs",
      iconName: "assignment_turned_in",
    },
    {
      totalCount: "...",
      label: "statistics.transactions",
      iconName: "assignment",
    },
  ],
  tableFields: [
    {
      label: "table.orgName",
    },
    {
      label: "table.allReservations",
    },
    {
      label: "table.bookedReservations",
    },
    {
      label: "table.completedReservations",
    },
    {
      label: "table.cancelledReservations",
    },
    {
      label: "table.createdOn",
    },
  ],
  orgsActivityLoading: false,
  orgsActivity: [],
  currentOffset: 0,
  pageOffset: 10,

  //callvita fees
  fees: [],
  feesLoading: false,
  currencies: [],
  editError: false,
};

export const admin = (state = initialState, action) => {
  switch (action.type) {
    case GET_CURRENCY_SUCCESS:
      return {
        ...state,
        currencies: action.payload,
      };
    case RESET_EDIT_ERROR:
      return {
        ...state,
        editError: false,
      };

    case CREATE_CALLVITA_FEES_SUCCESS:
      let newFees = [...state.fees];
      newFees.push(action.payload);
      return {
        ...state,
        fees: newFees,
      };

    case DELETE_CALLVITA_FEES_SUCCESS:
      const deletedFees = action.payload;
      const newFeesDeleted = state.fees.filter((fee) => {
        return fee.id !== deletedFees;
      });
      return {
        ...state,
        fees: newFeesDeleted,
      };

    case EDIT_CALLVITA_FEES_SUCCESS:
      const editedFees = action.payload;
      const newFessEdited = [...state.fees];
      const feesIndex = newFessEdited.findIndex((fee) => {
        return fee.id === editedFees.id;
      });
      newFessEdited[feesIndex] = editedFees;
      return {
        ...state,
        fees: newFessEdited,
      };

    case EDIT_CALLVITA_FEES_FAILED:
      return {
        ...state,
        editError: true,
      };

    case SET_EDIT_MODAL:
      return {
        ...state,
        editModalBoolean: action.payload,
      };

    case SET_ADD_MODAL:
      return {
        ...state,
        addModalBoolean: action.payload,
      };

    case GET_TOTAL_STATS:
      return {
        ...state,
        totalStatsLoading: true,
        totalStats: state.totalStats,
      };

    case TOTAL_STATS_RETURNED:
      let { totalStats } = action.payload;
      return {
        ...state,
        totalStatsLoading: false,
        totalStats: action.payload.error ? state.totalStats : totalStats,
      };

    case GET_ORGS_ACTIVITY:
      return {
        ...state,
        orgsActivityLoading: true,
      };

    case ORGS_AVTIVITY_RETURNED:
      let { orgsActivity } = action.payload;
      return {
        ...state,
        orgsActivityLoading: false,
        orgsActivity: action.payload.error ? state.orgsActivity : orgsActivity,
      };

    case GET_CALLVITA_FEES_REQUEST:
      return {
        ...state,
        feesLoading: true,
      };

    case GET_CALLVITA_FEES_SUCCESS:
      return {
        ...state,
        fees: action.payload,
        feesLoading: false,
      };

    case GET_CALLVITA_FEES_FAILED:
      return {
        ...state,
        feesLoading: false,
      };
    case SEND_NOTIFICATION:
      return {
        notification: action.payload,
      };
    default:
      return state;
  }
};
