import { all, takeEvery, put, call } from 'redux-saga/effects';
import Preconditions from '../../../../../core/utils/Preconditions';
import {
	MY_CLINICS_REQUESTED,
	MY_CLINICS_RETURNED,
	GET_ALL_CLINICS_REQUESTED,
	GET_ALL_CLINICS_RETURNED,
	SEARCH_RETURNED,
	SEARCH_REQUESTED,
	ADD_CLINIC_TO_USER_REQUEST,
	ADD_USER_CLINIC_RETURNED,
	DELETE_CLINIC_REQUESTED,
	DELETE_CLINIC_RETURNED
} from '../constants/ActionTypes';
import { showErrorMessage, showSuccessMessage } from '../../../../../../actions/Notifications';
import { clinicToCLinicListItem } from '../converters/clinicToClinicListItem';
import { getTokens, getOrgsApiGWClient } from '../../../../../core/utils/auth-functions';
import { nativeTypes } from '../../../../../core/utils/org-types';

const errorMessage = "Something went wrong. Please try again later.";

function convertObject(data) {
	let obj = JSON.parse(JSON.stringify(data))
	return obj;
}

export function* getMyClinics(action) {
	Preconditions.shouldBeDefined(action, "Get my clinics Action must be defined");
	const userId = { id: action.payload }
	let orgs = [];
	let msg = '';

	let additionalParams = {
		queryParams: {
			data: JSON.stringify(userId)
		}
	};
	let pathTemplate = '/api/users';
	let method = 'get';
	let error = false;

	const tokens = yield call(getTokens);
	if (!tokens[3]) {
		const apigClient = getOrgsApiGWClient(tokens[0], tokens[1], tokens[2]);
		yield apigClient.invokeApi({}, pathTemplate, method, additionalParams, {})
			.then(response => {
				if (response.data.success) {
					msg = "Clinics retrieved successfully.";
					orgs = response.data.payload;
				} else {
					msg = response.data.message ? response.data.message : errorMessage;
					error = true;
				}
			})
			.catch(_ => {
				msg = errorMessage;
				error = true;
			});
	}
	else {
		msg = errorMessage;
		error = true;
	}

	let convertedOrgs = [];
	if (!error) {
		convertedOrgs = orgs.map(org => {
			return clinicToCLinicListItem(org);
		});
	}
	else {
		yield put(showErrorMessage(msg));
	}

	yield put({
		type: MY_CLINICS_RETURNED,
		payload: { orgs: convertedOrgs, error }
	});
}

export function* getAllClinics() {
	let orgs = [];
	let msg = '';

	let pathTemplate = '/api/all';
	let method = 'get';
	let error = false;

	const tokens = yield call(getTokens);
	if (!tokens[3]) {
		const apigClient = getOrgsApiGWClient(tokens[0], tokens[1], tokens[2]);
		yield apigClient.invokeApi({}, pathTemplate, method, {}, {})
			.then(response => {
				if (response.data.success) {
					msg = "clinics retrieved successfully."
					orgs = response.data.payload;
				} else {
					msg = response.data.message ? response.data.message : errorMessage;
					error = true;
				}
			})
			.catch(_ => {
				msg = errorMessage;
				error = true;
			});
	}
	else {
		msg = errorMessage;
		error = true;
	}
	let convertedOrgs = [];
	if (!error) {
		convertedOrgs = orgs.map(org => {
			return clinicToCLinicListItem(org);
		})
	}
	else {
		yield put(showErrorMessage(msg));
	}
	yield put({
		type: GET_ALL_CLINICS_RETURNED,
		payload: { orgs: convertedOrgs, error }
	})
}

export function* searchClinics(action) {
	Preconditions.shouldBeDefined(action, "Search Organizations Action must be defined");
	const filters = action.payload;
	let orgs = [];
	let filtersModified = {}
	if (filters.type === "all") {
		filtersModified = {
			types: nativeTypes,
			name: filters.name
		}
	}
	else {
		filtersModified = {
			types: [filters.type],
			name: filters.name
		}
	}

	let additionalParams = {
		queryParams: {
			data: JSON.stringify(filtersModified)
		}
	};
	let pathTemplate = '/api/users/search';
	let method = 'get';
	let error = false;
	let msg = '';

	const tokens = yield call(getTokens);
	if (!tokens[3]) {
		const apigClient = getOrgsApiGWClient(tokens[0], tokens[1], tokens[2]);
		yield apigClient.invokeApi({}, pathTemplate, method, additionalParams, {})
			.then(response => {
				if (response.data.success) {
					msg = "Clinics retrieved successfully.";
					orgs = response.data.payload;
				} else {
					msg = response.data.message ? response.data.message : errorMessage;
					error = true;
				}
			})
			.catch(_ => {
				msg = errorMessage;
				error = true;
			});
	}
	else {
		msg = errorMessage;
		error = true;
	}

	let convertedOrgs = [];
	if (!error) {
		convertedOrgs = orgs.map(org => {
			return clinicToCLinicListItem(org);
		})
	}
	else {
		yield put(showErrorMessage(msg));
	}

	yield put({
		type: SEARCH_RETURNED,
		payload: { orgs: convertedOrgs, error }
	})
}

export function* addClinicToUserRequest(action) {
	Preconditions.shouldBeDefined(action, "Add Clinic To User Action must be defined");
	let error = false;
	let msg = '';
	let user = { ...action.payload.user, checked: action.payload.user.canAccess ? true : false }
	const { org } = action.payload;
	org.status = 'pending';
	const request = {
		role: "user",
		orgId: org.id,
		userId: action.payload.userId,
		status: "pending",
		user
	}

	let body = convertObject(request);
	let pathTemplate = '/api/users';
	let method = 'post';

	const tokens = yield call(getTokens);
	if (!tokens[3]) {
		const apigClient = getOrgsApiGWClient(tokens[0], tokens[1], tokens[2]);
		yield apigClient.invokeApi({}, pathTemplate, method, {}, body)
			.then(response => {
				if (response.data.success) {
					msg = "messages.subscribeSuccess";
				}
				else {
					msg = response.data.message ? response.data.message : errorMessage;
					error = true;
				}
			})
			.catch(e => {
				error = true;
				msg = errorMessage;
			});
	}
	else {
		error = true;
		msg = errorMessage;
	}

	if (error) {
		yield put(showErrorMessage(msg));
	}
	else {
		yield put(showSuccessMessage(msg));
	}

	yield put({
		type: ADD_USER_CLINIC_RETURNED,
		payload: { org, error }
	})
}

export function* deleteClinic(action) {
	const { org } = action.payload;
	let msg = ''
	const request = {
		orgId: org.id,
		userId: action.payload.userId
	}

	let deleted = false;
	let additionalParams = {
		queryParams: {
			data: JSON.stringify(request)
		}
	};
	let pathTemplate = '/api/users';
	let method = 'delete';
	let error = false;

	const tokens = yield call(getTokens);
	if (!tokens[3]) {
		const apigClient = getOrgsApiGWClient(tokens[0], tokens[1], tokens[2]);

		yield apigClient.invokeApi({}, pathTemplate, method, additionalParams, {})
			.then(response => {
				if (response.data.success && response.data.payload === 1) {
					msg = "messages.deleteSubsSuccess";
					deleted = true;
				}
				else if (!response.data.success && response.data.payload === 0) {
					msg = 'You cannot delete this clinic because you are added to a queue inside inside this clinic';
					deleted = false;
				}
				else if (!response.data.success) {
					msg = errorMessage;
					error = true;
				}
			})
			.catch(_ => {
				msg = errorMessage;
				error = true;
			});
	}
	else {
		msg = errorMessage;
		error = true;
	}

	if (error) {
		yield put(showErrorMessage(msg));
	}
	else if (!error && deleted) {
		yield put(showSuccessMessage(msg));
	}

	yield put({
		type: DELETE_CLINIC_RETURNED,
		payload: { org, deleted, error }
	})
}

export default function* rootSaga() {
	yield all([
		yield takeEvery(MY_CLINICS_REQUESTED, getMyClinics),
		yield takeEvery(GET_ALL_CLINICS_REQUESTED, getAllClinics),
		yield takeEvery(SEARCH_REQUESTED, searchClinics),
		yield takeEvery(ADD_CLINIC_TO_USER_REQUEST, addClinicToUserRequest),
		yield takeEvery(DELETE_CLINIC_REQUESTED, deleteClinic)
	]);
}