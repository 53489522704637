import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { FormControl, FormHelperText, TextField, Button, CircularProgress } from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import * as EmailValidator from 'email-validator';
import {
  showAuthLoader,
  userForgotPassword,
  onEmailChange
} from 'actions/Auth';
import { resetSweetSuccess } from 'actions/Notifications';
import SweetAlert from 'react-bootstrap-sweetalert';

class ForgetPassword extends Component {
  constructor() {
    super();
    this.state = {
      emailError: ''
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.email !== this.props.email) {
      this.setState({ email: this.props.email })
    }
  }

  onConfirm = () => {
    this.props.resetSweetSuccess();
    this.props.history.push('/reset_password');
  }

  render() {
    const { emailError } = this.state;
    const { loader, alertMessage, sweetSuccess, email } = this.props;
    const enabled = email && !emailError;

    return (
      <React.Fragment>
        <div
       className='blue-bg'
      >
        <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
          <div className="app-login-main-content">
            <div
              className="app-logo-content d-flex align-items-center justify-content-center"
              style={{ backgroundColor: "#fff" }}
            >
              <Link className="logo-lg" to="/" title="CALLVITA">
                <img
                  src={require("assets/images/srvy-blue.png")}
                  alt="Srvy"
                  title="Srvy"
                  style={{ width: "75%" }}
                />
              </Link>
            </div>

            <div className="app-login-content">
              <div className="app-login-header mb-4">
                <h1>
                  <IntlMessages id="appModule.forgotPassword" />
                </h1>
              </div>

              <div className="app-login-form">
                <form>
                  <fieldset>
                    <FormControl fullWidth>
                      <TextField
                        label={<IntlMessages id="appModule.email" />}
                        fullWidth
                        onChange={(event) => {
                          if (EmailValidator.validate(event.target.value)) {
                            this.setState({ emailError: "" });
                          } else {
                            this.setState({
                              emailError: (
                                <IntlMessages id="validations.emailError" />
                              ),
                            });
                          }
                          this.props.onEmailChange(event.target.value);
                        }}
                        value={email}
                        className="mt-0 mb-3"
                        required
                      />
                      {this.state.emailError && (
                        <FormHelperText
                          id="component-error-text"
                          style={{ marginBottom: 8 }}
                          error
                        >
                          {this.state.emailError}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <div className="mb-3 d-flex align-items-center justify-content-end">
                      <Button
                        onClick={() => {
                          this.props.history.push("/signin");
                        }}
                        variant="contained"
                      >
                        <IntlMessages id="default.cancel" />
                      </Button>
                      <Button
                        disabled={!enabled}
                        onClick={() => {
                          this.props.showAuthLoader();
                          this.props.forgetPassword(email);
                        }}
                        variant="contained"
                        color="primary"
                        style={
                          !this.props.isDirectionRTL
                            ? { marginLeft: 8 }
                            : { marginRight: 8 }
                        }
                      >
                        <IntlMessages id="appModule.submit" />
                      </Button>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
          {loader && (
            <div className="loader-view">
              <CircularProgress />
            </div>
          )}
          <SweetAlert
            show={sweetSuccess}
            success
            title={<IntlMessages id={alertMessage} />}
            onConfirm={this.onConfirm}
          />
        </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ auth, settings, notifications }) => {
  const { loader, email } = auth;
  const { sweetSuccess, alertMessage } = notifications;
  return { loader, alertMessage, sweetSuccess, email, isDirectionRTL: settings.isDirectionRTL };
};

const mapDispatchToProps = dispatch => {
  return {
    forgetPassword: (email) => {
      dispatch(userForgotPassword(email));
    },
    resetSweetSuccess: () => {
      dispatch(resetSweetSuccess());
    },
    showAuthLoader: () => {
      dispatch(showAuthLoader());
    },
    onEmailChange: (email) => {
      dispatch(onEmailChange(email));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgetPassword));