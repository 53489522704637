import {
	MY_CLINICS_RETURNED,
	GET_ALL_CLINICS_RETURNED,
	SEARCH_RETURNED,
	GET_ALL_CLINICS_REQUESTED,
	MY_CLINICS_REQUESTED,
	ADD_USER_CLINIC_RETURNED,
	DELETE_CLINIC_RETURNED,
	RESET_WARNING
} from '../constants/ActionTypes';
import { SIGNOUT_USER_SUCCESS } from '../../../../../../constants/ActionTypes';

const initialState = {
	myClinics: [],
	filteredClinics: [],
	selectedClinic: null,
	warning: false,
	loading: false,
}

export const clinics = (state = initialState, action) => {
	switch (action.type) {
		case MY_CLINICS_REQUESTED:
			return {
				...state,
				loading: true
			}

		case MY_CLINICS_RETURNED:
			if (!action.payload.error) {
				return {
					...state,
					myClinics: action.payload.orgs,
					alertMessage: action.payload.msg,
					loading: false
				}
			} else {
				return {
					...state,
					loading: false
				}
			}
			
		case GET_ALL_CLINICS_REQUESTED:
			return {
				...state,
				loading: true
			}

		case GET_ALL_CLINICS_RETURNED:
			if (!action.payload.error) {
				return {
					...state,
					filteredClinics: action.payload.orgs,
					loading: false
				}
			} else {
				return {
					...state,
					loading: false
				}
			}
		case SEARCH_RETURNED:
			if (!action.payload.error) {
				return {
					...state,
					filteredClinics: action.payload.orgs
				}
			} else {
				return {
					...state,
					loading: false
				}
			}

		case ADD_USER_CLINIC_RETURNED:
			if (!action.payload.error) {
				const newMyClinics = [...state.myClinics];
				newMyClinics.push(action.payload.org);
				return {
					...state,
					myClinics: newMyClinics
				}
			}
			return {
				...state,
				loading: false
			}

		case DELETE_CLINIC_RETURNED:
			if (!action.payload.error) {
				let newWarning = state.warning
				let newClinics = [...state.myClinics];
				if (action.payload.deleted) {
					const deletedClinic = action.payload.org;
					newClinics = state.myClinics.filter(clinic => {
						return clinic.id !== deletedClinic.id;
					});
				} else {
					newWarning = true
				}
				return {
					...state,
					alertMessage: action.payload.msg,
					myClinics: newClinics,
					warning: newWarning
				}
			} else {
				return {
					...state,
					loading: false
				}
			}

		case RESET_WARNING:
			return {
				...state,
				warning: false
			}

		case SIGNOUT_USER_SUCCESS:
			return initialState;

		default: {
			return state;
		}
	}
}
