import {
	INIT_URL,
	ON_HIDE_LOADER,
	ON_SHOW_LOADER,
	SIGNIN_FACEBOOK_USER_SUCCESS,
	SIGNIN_GITHUB_USER_SUCCESS,
	SIGNIN_GOOGLE_USER_SUCCESS,
	SIGNIN_TWITTER_USER_SUCCESS,
	SIGNIN_USER_SUCCESS,
	SIGNOUT_USER_SUCCESS,
	SIGNUP_USER_SUCCESS,
	SET_LOGGED_IN_USER,
	FORGOT_PASSWORD_RETURNED,
	NEW_PASSWORD_RETURNED,
	EMAIL_CHANGE,
	DEEP_LINKING
} from "../constants/ActionTypes";

const INIT_STATE = {
	email: '',
	loader: false,
	initURL: '',
	authUser: localStorage.getItem('user_id'),
	loggedInUser: JSON.parse(localStorage.getItem('loggedInUser')),
	searchDrId: ''
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case EMAIL_CHANGE: {
			return {
				...state,
				email: action.payload
			}
		}

		case SIGNUP_USER_SUCCESS: {
			return {
				...state,
				loader: false
			}
		}

		case DEEP_LINKING: {
			return {
				...state,
				searchDrId: action.payload
			}
		}

		case SIGNIN_USER_SUCCESS: {
			return {
				...state,
				loader: false,
				authUser: action.payload
			}
		}

		case SET_LOGGED_IN_USER:
			return {
				...state,
				loggedInUser: action.payload
			}

		case INIT_URL: {
			return {
				...state,
				initURL: action.payload
			}
		}

		case SIGNOUT_USER_SUCCESS: {
			return {
				...state,
				authUser: null,
				initURL: '/signin',
				loader: false
			}
		}

		case SIGNIN_GOOGLE_USER_SUCCESS: {
			return {
				...state,
				loader: false,
				authUser: action.payload
			}
		}

		case SIGNIN_FACEBOOK_USER_SUCCESS: {
			return {
				...state,
				loader: false,
				authUser: action.payload
			}
		}

		case SIGNIN_TWITTER_USER_SUCCESS: {
			return {
				...state,
				loader: false,
				authUser: action.payload
			}
		}

		case SIGNIN_GITHUB_USER_SUCCESS: {
			return {
				...state,
				loader: false,
				authUser: action.payload
			}
		}

		case ON_SHOW_LOADER: {
			return {
				...state,
				loader: true
			}
		}

		case ON_HIDE_LOADER: {
			return {
				...state,
				loader: false
			}
		}

		case FORGOT_PASSWORD_RETURNED:
			return {
				...state,
				loader: false
			}

		case NEW_PASSWORD_RETURNED: {
			return {
				...state,
				loader: false,
			}
		}

		default:
			return state;
	}
}
