const developEndpoints = {
    orgUrl: "https://ohfr813301.execute-api.eu-west-2.amazonaws.com/dev",
    eventsUrl: "https://c2xrdtsti7.execute-api.eu-west-2.amazonaws.com/dev",
    usersBaseUrl: "https://e9sz3uujvc.execute-api.eu-west-2.amazonaws.com/dev",
    resUrl: "https://ohfr813301.execute-api.eu-west-2.amazonaws.com/dev",
    paymentUrl: "https://26y4xl6nhi.execute-api.eu-west-2.amazonaws.com/dev",
    coreUrl: "https://p38yhppre3.execute-api.eu-west-2.amazonaws.com/dev"
}

const productionEndpoints = {
    orgUrl: 'https://r4o0gof2n3.execute-api.eu-west-2.amazonaws.com/v1',
    eventsUrl: 'https://ajirkm8nx0.execute-api.eu-west-2.amazonaws.com/v1',
    usersBaseUrl: 'https://to8i0eqk04.execute-api.eu-west-2.amazonaws.com/v1',
    resUrl: 'https://r4o0gof2n3.execute-api.eu-west-2.amazonaws.com/v1',
    paymentUrl: 'https://srnhk1xmo5.execute-api.eu-west-2.amazonaws.com/v1',
    coreUrl: "https://6fzx1hx4hh.execute-api.eu-west-2.amazonaws.com/v1"
}

export const endpoints = process.env.REACT_APP_STAGE === 'develop' ? developEndpoints : productionEndpoints;