import { Button, withStyles } from "@material-ui/core";
import { NavLink, withRouter } from "react-router-dom";
import React, { Component } from "react";

import CustomScrollbars from "util/CustomScrollbars";
import IntlMessages from "util/IntlMessages";

const styles = (theme) => ({
  menuItem: {
    paddingRight: 10,
    paddingTop: 5,
    marginRight: 5,
  },
});

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`; // get current path

    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function(event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, "li");
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains("open"))
          ) {
            menuLi[j].classList.remove("open");
          }
        }
        this.classList.toggle("open");
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function(fn) {
        if (typeof document.body[fn] == "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}
    return null;
  }

  render() {
    const { user } = this.props;
    const permissions = user.permissions;
    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
          <li className="nav-header">
            <IntlMessages id="sidebar.main" />
          </li>
          {(permissions.includes("VIEW_ORG_DETAILED_USERS") ||
            permissions.includes("VIEW_PROVIDERS_CALENDAR")) && (
            <li className="menu open">
              <Button>
                <i className="zmdi zmdi-view-list zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.clinic" />
                </span>
              </Button>
              <ul className="sub-menu open">
                {permissions.includes("VIEW_ORG_DETAILED_USERS") && (
                  <li>
                    <NavLink to="/app/clinic/users" className="prepend-icon">
                      <span className="nav-text">
                        <IntlMessages id="user.sidebar.title" />{" "}
                      </span>
                    </NavLink>
                  </li>
                )}
                {permissions.includes("VIEW_PROVIDERS_CALENDAR") && (
                  <li>
                    <NavLink
                      to="/app/clinic/providerCalendar"
                      className="prepend-icon"
                    >
                      <span className="nav-text">
                        <IntlMessages id="reservation.providerCalendar" />
                      </span>
                    </NavLink>
                  </li>
                )}
                {permissions.includes("VIEW_RESERVATIONS") && (
                  <li>
                    <NavLink
                      to="/app/clinic/reservations"
                      className="prepend-icon"
                    >
                      <span className="nav-text">
                        <IntlMessages id="reservation.reservation" />
                      </span>
                    </NavLink>
                  </li>
                )}
                {permissions.includes("VIEW_OWN_PATIENTS_QUEUE") && (
                  <li>
                    <NavLink to="/app/doctorpanel" className="prepend-icon">
                      <span className="nav-text">
                        <IntlMessages id="sidebar.patientsQueue" />{" "}
                      </span>
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink to="/app/wallet" className="prepend-icon">
                    <span className="nav-text">
                      <IntlMessages id="sidebar.wallet" />{" "}
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
          )}
          {(permissions.includes("VIEW_OWN_EVENTS") ||
            permissions.includes("VIEW_OWN_CLINICS") ||
            permissions.includes("VIEW_OWN_SUMMARY")) && (
            <li className="menu open">
              <Button>
                <i className="zmdi zmdi-view-list zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.myHealth.default" />
                </span>
              </Button>
              <ul className="sub-menu open">
                {permissions.includes("FIND_A_DOCTOR") && (
                  <li className="open">
                    <NavLink
                      className="prepend-icon"
                      to="/app/reservations/find-doctor"
                    >
                      <span className="nav-text">
                        <IntlMessages id="sidebar.findADoctor" />
                      </span>
                    </NavLink>
                  </li>
                )}
                {permissions.includes("VIEW_OWN_USER_RESERVATIONS") && (
                  <li>
                    <NavLink
                      className="prepend-icon"
                      to="/app/my-health/myReservations"
                    >
                      <span className="nav-text">
                        <IntlMessages id="sidebar.myHealth.myReservations" />
                      </span>
                    </NavLink>
                  </li>
                )}
                {permissions.includes("VIEW_OWN_SUMMARY") && (
                  <li>
                    <NavLink
                      className="prepend-icon"
                      to="/app/my-health/summary"
                    >
                      <span className="nav-text">
                        <IntlMessages id="sidebar.myHealth.summary" />
                      </span>
                    </NavLink>
                  </li>
                )}
                {permissions.includes("VIEW_OWN_EVENTS") && (
                  <li>
                    <NavLink
                      className="prepend-icon"
                      to="/app/my-health/events"
                    >
                      <span className="nav-text">
                        <IntlMessages id="sidebar.myHealth.events" />
                      </span>
                    </NavLink>
                  </li>
                )}
                {permissions.includes("VIEW_OWN_CLINICS") && (
                  <li>
                    <NavLink
                      className="prepend-icon"
                      to="/app/my-health/myClinics"
                    >
                      <span className="nav-text">
                        <IntlMessages id="sidebar.myHealth.clinics" />
                      </span>
                    </NavLink>
                  </li>
                )}
              </ul>
            </li>
          )}

          {(permissions.includes("VIEW_DETAILED_ORGS") ||
            permissions.includes("VIEW_ALL_DETAILED_USERS") ||
            permissions.includes("VIEW_ADMIN_STATISTICS") ||
            permissions.includes("VIEW_TRANSACTIONS_STATISTICS") ||
            permissions.includes("SEND_NOTIFICATION") ||
            permissions.includes("VIEW_CALLVITA_FEES")) && (
            <li className="menu open">
              <Button>
                <i className="zmdi zmdi-view-list zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.adminPanel" />
                </span>
              </Button>
              <ul className="sub-menu open">
                {permissions.includes("VIEW_ADMIN_STATISTICS") && (
                  <li>
                    <NavLink
                      to="/app/adminpanel/statistics"
                      className="prepend-icon"
                    >
                      <span className="nav-text">
                        <IntlMessages id="sidebar.statistics" />{" "}
                      </span>
                    </NavLink>
                  </li>
                )}

                {permissions.includes("VIEW_DETAILED_ORGS") && (
                  <li>
                    <NavLink
                      to="/app/adminpanel/organizations"
                      className="prepend-icon"
                    >
                      <span className="nav-text">
                        <IntlMessages id="sidebar.organizations" />{" "}
                      </span>
                    </NavLink>
                  </li>
                )}

                {permissions.includes("VIEW_ALL_DETAILED_USERS") && (
                  <li>
                    <NavLink
                      to="/app/adminpanel/users"
                      className="prepend-icon"
                    >
                      <span className="nav-text">
                        <IntlMessages id="admin.sidebar.title" />{" "}
                      </span>
                    </NavLink>
                  </li>
                )}

                {permissions.includes("VIEW_TRANSACTIONS_STATISTICS") && (
                  <li>
                    <NavLink
                      to="/app/adminpanel/transactions"
                      className="prepend-icon"
                    >
                      <span className="nav-text">
                        <IntlMessages id="TrStats.sidebarTitle" />{" "}
                      </span>
                    </NavLink>
                  </li>
                )}

                {permissions.includes("VIEW_CALLVITA_FEES") && (
                  <li>
                    <NavLink to="/app/adminpanel/fees" className="prepend-icon">
                      <span className="nav-text">
                        <IntlMessages id="fees.sidebarTitle" />{" "}
                      </span>
                    </NavLink>
                  </li>
                )}
                {permissions.includes("VIEW_CALLVITA_FEES") && (
                  <li>
                    <NavLink
                      to="/app/adminpanel/dailyReservations"
                      className="prepend-icon"
                    >
                      <span className="nav-text">
                        <IntlMessages id="daily_reservations_activity.sidebar_title" />
                      </span>
                    </NavLink>
                  </li>
                )}
                {permissions.includes("VIEW_CALLVITA_FEES") && (
                  <li>
                    <NavLink
                      to="/app/adminpanel/dailyChatReservations"
                      className="prepend-icon"
                    >
                      <span className="nav-text">
                        <IntlMessages id="chat_reservations_activity.sidebar_title" />
                      </span>
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink
                    to="/app/adminpanel/notifications"
                    className="prepend-icon"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.notifications" />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
          )}
        </ul>
      </CustomScrollbars>
    );
  }
}

export default withStyles(styles)(withRouter(SidenavContent));
