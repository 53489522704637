import { all, takeEvery, put } from "redux-saga/effects";
import { showErrorMessage } from "../../../../../actions/Notifications";
import { paymentApiClient } from "../../../../core/utils/restClient";
import {
  GET_TRANSACTIONS_STATS,
  GET_TRANSACTION_BY_ID_REQUEST,
  REFUND_TRANSACTION_REQUEST,
  PAYOUT_TRANSACTION_REQUEST,
} from "../constants/actionTypes";
import {
  getTransactionsStatsSuccess,
  getTransactionsStatsFailed,
} from "../actions/adminActions";
import {
  getTransactionsByIdSuccess,
  getTransactionByIdFailed,
  refundTransactionSuccess,
  refundTransactionFailed,
  payoutTransactionSuccess,
  payoutTransactionFailed,
} from "../actions/transactionsActions";

const errorMessage = "Something went wrong, please try again later.";

export function* getTransactionsStats(action) {
  let pathTemplate = "/api/transactions-stats";
  const myInit = {
    queryStringParameters: {
      ...action.payload,
    },
  };
  try {
    const res = yield paymentApiClient.get(pathTemplate, myInit);
    if (res.success) {
      return yield put(getTransactionsStatsSuccess(res.payload));
    }
    yield put(getTransactionsStatsFailed());
    yield put(showErrorMessage(res.message || errorMessage));
  } catch (e) {
    yield put(getTransactionsStatsFailed());
    yield put(showErrorMessage(errorMessage));
  }
}

export function* getTransactionByIdRequest(action) {
  let pathTemplate = "/api/transaction-details";
  const myInit = {
    queryStringParameters: {
      id: action.payload,
    },
  };
  try {
    const res = yield paymentApiClient.get(pathTemplate, myInit);
    if (res.success) {
      console.log(res.payload);
      return yield put(getTransactionsByIdSuccess(res.payload));
    }
    yield put(getTransactionByIdFailed());
    yield put(showErrorMessage(res.message || errorMessage));
  } catch (e) {
    yield put(getTransactionByIdFailed());
    yield put(showErrorMessage(errorMessage));
  }
}

export function* refundTransactionRequest(action) {
  try {
    const pathTemplate = `/api/transactions/${action.payload}/refund`;
    const myInit = {};
    const res = yield paymentApiClient.post(pathTemplate, myInit);
    if (res.success) {
      return yield put(refundTransactionSuccess(res.payload));
    }
    yield put(refundTransactionFailed());
    yield put(showErrorMessage(res.message || errorMessage));
  } catch (e) {
    yield put(refundTransactionFailed());
    yield put(showErrorMessage(errorMessage));
  }
}

export function* payoutTransactionRequest(action) {
  try {
    const pathTemplate = `/api/transactions/${action.payload}/payout`;
    const myInit = {};
    const res = yield paymentApiClient.post(pathTemplate, myInit);
    if (res.success) {
      return yield put(payoutTransactionSuccess(res.payload));
    }
    yield put(payoutTransactionFailed());
    yield put(showErrorMessage(res.message || errorMessage));
  } catch (e) {
    yield put(payoutTransactionFailed());
    yield put(showErrorMessage(errorMessage));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(GET_TRANSACTIONS_STATS, getTransactionsStats),
    takeEvery(GET_TRANSACTION_BY_ID_REQUEST, getTransactionByIdRequest),
    takeEvery(REFUND_TRANSACTION_REQUEST, refundTransactionRequest),
    takeEvery(PAYOUT_TRANSACTION_REQUEST, payoutTransactionRequest),
  ]);
}
