import {
  VITALS_RETURNED,
  RESET_VITAL_MODAL,
  VITAL_NOTE_ADDED,
  GET_NOTES_RETURNED,
  UPDATE_SELECTED_NOTE,
  VITAL_NOTE_EDITED,
  VITALS_NOTE_DELETED,
  RESET_EDIT_EVENT_MODE
} from './../constants/ActionTypes';

const initialState = {
  vitals: [],
  draftVitalNote: null,
  selectedVitalNote: null,
  vitalsNotes: [],
  loading: false
}

export const vitalsReducer = (state = initialState, action) => {
  switch (action.type) {

    case VITALS_RETURNED:
      if (action.payload.error) {
        return {
          ...state,
          loading: false,
        }
      }
      return {
        ...state,
        vitals: action.payload.vitals,
        loading: false
      }

    case RESET_EDIT_EVENT_MODE:
      return {
        ...state,
        vitalsNotes: []
      }

    case RESET_VITAL_MODAL:
      return {
        ...state,
        vitals: []
      }

    case VITAL_NOTE_ADDED:
      if (!action.payload.error) {
        const newVitals = [...state.vitalsNotes];
        newVitals.unshift(action.payload.convertedNote);
        return {
          ...state,
          vitalsNotes: newVitals,
          draftVitalNote: null,
          vitals: []
        }
      } else {
        return {
          ...state
        }
      }

    case GET_NOTES_RETURNED:
      if (!action.payload.error) {
        return {
          ...state,
          vitalsNotes: action.payload.vitalsNotes,
        }
      }
      return {
        ...state
      }

    case UPDATE_SELECTED_NOTE:
      if (action.payload.type === "vitals") {
        let newDraftNote = {};
        const selectedNote = action.payload.note;
        selectedNote.records.forEach(record => {
          newDraftNote[record.id] = { ...record, error: '' };
        })
        return {
          ...state,
          selectedVitalNote: action.payload.note,
          draftVitalNote: newDraftNote
        }
      }
      return { ...state };

    case VITAL_NOTE_EDITED:
      if (action.payload.error) {
        return {
          ...state,
          selectedVitalNote: null,
          draftVitalNote: null
        }
      }
      const editedNote = action.payload.updatedNote;
      const newNotes = [...state.vitalsNotes];
      const noteIndex = newNotes.findIndex(note => {
        return note.id === editedNote.id;
      });
      newNotes[noteIndex] = editedNote;

      return {
        ...state,
        draftVitalNote: null,
        selectedVitalNote: null,
        vitalsNotes: newNotes
      }

    case VITALS_NOTE_DELETED:
      if (action.payload.error) {
        return {
          ...state
        }
      }
      const deletedNote = action.payload.deletedNote;
      const filteredNotes = state.vitalsNotes.filter(note => {
        return note.id !== deletedNote.id;
      });

      return {
        ...state,
        vitalsNotes: filteredNotes
      }

    default:
      return state
  }
}