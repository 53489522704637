import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormControl, FormHelperText, TextField, Button, CircularProgress } from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import {
  showAuthLoader,
  userSubmitNewPassword
} from 'actions/Auth';
import { resetSweetSuccess } from 'actions/Notifications';
import SweetAlert from 'react-bootstrap-sweetalert';

class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      code: '',
      newPassword: '',
      confirmNewPassword: '',
      passwordLength: '',
      confirmPassError: ''
    }
  }

  handlePasswordChange = event => {
    if (event.target.value.length >= 8) {
      this.setState({ newPassword: event.target.value, passwordLength: '' })
    } else {
      this.setState({ newPassword: event.target.value, passwordLength: <IntlMessages id="password.errorMessage" /> });
    }
  }

  handleConfirmPasswordChange = event => {
    if (event.target.value === this.state.newPassword) {
      this.setState({ confirmNewPassword: event.target.value, confirmPassError: '' })
    } else {
      this.setState({ confirmNewPassword: event.target.value, confirmPassError: <IntlMessages id="password.confirmErrorMessage" /> });
    }
  }

  onConfirm = () => {
    this.props.resetSweetSuccess();
    this.props.history.push('/signin');
  }

  render() {
    const { code, newPassword, passwordLength, confirmNewPassword, confirmPassError } = this.state;
    const { loader, alertMessage, sweetSuccess } = this.props;
    const enabled = code && newPassword && confirmNewPassword && !passwordLength && !confirmPassError;

    return (
      <React.Fragment>
        <div
       className='blue-bg'
      >
        <div
          className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
          <div className="app-login-main-content">

            <div className="app-logo-content d-flex align-items-center justify-content-center" style={{backgroundColor: '#fff'}}>
              <Link className="logo-lg" to="/" title="CALLVITA">
                <img src={require("assets/images/srvy-blue.png")} alt="Srvy" title="Srvy" style={{width: '75%'}} />
              </Link>
            </div>

            <div className="app-login-content">
              <div className="app-login-header mb-4">
                <h1><IntlMessages id="appModule.resetPassword" /></h1>
              </div>

              <div className="app-login-form">
                <form>
                  <fieldset>
                    <FormControl fullWidth>
                      <TextField
                        type="number"
                        label={<IntlMessages id="appModule.code" />}
                        fullWidth
                        onChange={(event) => {
                          this.setState({ code: event.target.value });
                        }}
                        value={code}
                        className="mt-0 mb-3"
                        required
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <TextField
                        type="password"
                        error={passwordLength ? true : false}
                        label={<IntlMessages id="appModule.newPassword" />}
                        onChange={(e) => this.handlePasswordChange(e)}
                        fullWidth
                        value={newPassword}
                        className="mt-0 mb-2"
                        required
                      />
                      {passwordLength &&
                        <FormHelperText id="component-error-text" error>{passwordLength}</FormHelperText>
                      }
                    </FormControl>

                    <FormControl fullWidth>
                      <TextField
                        type="password"
                        error={confirmPassError ? true : false}
                        label={<IntlMessages id="appModule.confirmNewPassword" />}
                        onChange={(e) => this.handleConfirmPasswordChange(e)}
                        fullWidth
                        value={confirmNewPassword}
                        className="mt-0 mb-2"
                        required
                      />
                      {passwordLength &&
                        <FormHelperText id="component-error-text" error>{confirmPassError}</FormHelperText>
                      }
                    </FormControl>

                    <div className="mb-3 d-flex align-items-center justify-content-end">
                      <Button onClick={() => {
                        this.props.history.push('/signin')
                      }}
                        variant="contained"
                      >
                        <IntlMessages id="default.cancel" />
                      </Button>
                      <Button disabled={!enabled} onClick={() => {
                        this.props.showAuthLoader();
                        this.props.resetPassword(this.props.email, code, newPassword);
                      }}
                        variant="contained"
                        color="primary"
                        style={!this.props.isDirectionRTL ? { marginLeft: 8 } : { marginRight: 8 }}
                      >
                        <IntlMessages id="appModule.reset" />
                      </Button>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>

          </div>
          {
            loader &&
            <div className="loader-view">
              <CircularProgress />
            </div>
          }
          <SweetAlert show={sweetSuccess} success title={<IntlMessages id={alertMessage} />}
            onConfirm={this.onConfirm} />

        </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ auth, notifications, settings }) => {
  const { loader, email } = auth;
  const { alertMessage, sweetSuccess } = notifications;
  return { loader, alertMessage, sweetSuccess, email, isDirectionRTL: settings.isDirectionRTL };
};

const mapDispatchToProps = dispatch => {
  return {
    resetPassword: (email, code, newPassword) => {
      dispatch(userSubmitNewPassword(email, code, newPassword));
    },
    resetSweetSuccess: () => {
      dispatch(resetSweetSuccess());
    },
    showAuthLoader: () => {
      dispatch(showAuthLoader());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPassword));