import firebase from 'firebase'
import ReduxSagaFirebase from 'redux-saga-firebase'
import '@firebase/firestore'
// Initialize Firebase
const config = {
  apiKey: "AIzaSyCNH9l1X8xyi-KHVeiAVIoXbFgkExtpws0",
  authDomain: "callvita-test.firebaseapp.com",
  databaseURL: "https://callvita-test.firebaseio.com",
  projectId: "callvita-test",
  storageBucket: "callvita-test.appspot.com",
  messagingSenderId: "794783930030",
  appId: "1:794783930030:web:ec3e03f4af20f268"
};
const myFirebaseApp = firebase.initializeApp(config);
const rsf = new ReduxSagaFirebase(myFirebaseApp)

const auth = firebase.auth();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

const database = firebase.firestore();
export {
  auth,
  database,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
  rsf
};