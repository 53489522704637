export const SAVE_USER = "SAVE_USER";
export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";
export const USERS_RETURNED = "USERS_RETURNED";
export const UPDATE_BASIC_INFO = "UPDATE_BASIC_INFO";
export const UPDATE_BASIC_INFO_ERRORS = "UPDATE_BASIC_INFO_ERRORS";
export const USER_SELECTED = "USER_SELECTED";
export const GET_USER = "GET_USER";
export const SEARCH_USERS = "SEARCH_USERS";
export const USERS_SEARCH_RETURNED = "USERS_SEARCH_RETURNED";
export const GET_USER_RETURNED = "GET_USER_RETURNED";
export const USER_EDIT_MODE = "USER_EDIT_MODE";
export const GET_USER_EDIT = "GET_USER_EDIT";
export const GET_USER_EDIT_RETURNED = "GET_USER_EDIT_RETURNED";
export const UPDATE_USER = "UPDATE_USER";
export const USER_UPDATED = "USER_UPDATED";
export const OPEN_BASIC_INFO_DIALOG = "OPEN_BASIC_INFO_DIALOG";
export const UPDATE_SELECTED_USER = "UPDATE_SELECTED_USER";
export const UPDATE_USER_EDIT = "update_user_edit";
export const UPDATE_SAVE_ENABLED = "UPDATE_SAVE_ENABLED";
export const GET_ORG_USERS_REQUEST = "GET_ORG_USERS_REQUEST";
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_RETURNED = "DELETE_USER_RETURNED";
export const SET_DELETE_MODE = "SET_DELETE_MODE";
export const ADD_USERS_TO_QUEUE_REQUESTED = "ADD_USERS_TO_QUEUE_REQUESTED";
export const ADD_USERS_TO_QUEUE_RETURNED = "ADD_USERS_TO_QUEUE_RETURNED";
export const SET_SELECT_QUEUE_MODE = "SET_SELECT_QUEUE_MODE";
export const SET_DR_ID = "SET_DR_ID";
export const GET_DR_DATA_REQUESTED = "GET_DR_DATA_REQUESTED";
export const GET_DR_DATA_RETURNED = "GET_DR_DATA_RETURNED";
export const RESET_DELETE_ENABLE = "RESET_DELETE_ENABLE";
export const RESET_QUEUE_ENABLE = "RESET_QUEUE_ENABLE";
export const QUEUE_USERS_REQUESTED = "QUEUE_USERS_REQUESTED";
export const QUEUE_USERS_RETURNED = "QUEUE_USERS_RETURNED";
export const RESET_DR_ID_APPS_MODAL = "RESET_DR_ID_APPS_MODAL";
export const DELETE_QUEUE_USER_REQUESTED = "DELETE_QUEUE_USER_REQUESTED";
export const DELETE_QUEUE_USER_RETURNED = "DELETE_QUEUE_USER_RETURNED";
export const SET_DR_ID_APPS_MODAL = "SET_DR_ID_APPS_MODAL";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const UPDATE_ADMIN_FILTERS = "UPDATE_ADMIN_FILTERS";
export const GET_ADMIN_FILTERED_USERS = "GET_ADMIN_FILTERED_USERS";
export const GET_ADMIN_FILTERED_USERS_RETURNED = "GET_ADMIN_FILTERED_USERS_RETURNED";
export const CHANGE_USERS_STATUS_REQUEST = "CHANGE_USERS_STATUS_REQUEST";
export const CHANGE_USERS_STATUS_RETURNED = "CHANGE_USERS_STATUS_RETURNED";
export const RESET_ERROR = "RESET_ERROR";
export const SEARCH = "SEARCH";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const CHECKED = "CHECKED";
export const SET_SELECT_RESERVE_MODE = "SET_SELECT_RESERVE_MODE";
export const RESET_RESERVE = "RESET_RESERVE";
export const RESERVE_TIME = "RESERVE_TIME";
export const RESERVE_OPEN = "RESERVE_OPEN";
export const SET_RESERVE_DR_ID = 'SET_RESERVE_DR_ID';
export const TIMINGS_RETURNED = 'TIMINGS_RETURNED';
export const SLOT_SELECTED = 'SLOT_SELECTED';
export const FINAL_RESERVE = 'FINAL_RESERVE';
export const RESET_RESERVE_DRAWER = 'RESET_RESERVE_DRAWER';
export const RESERVED = "RESERVED";
export const RESET_USERS = "RESET_USERS";
export const RESET_TIME_DURATION = "RESET_TIME_DURATION";
