import React from "react";
import { connect } from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";
import IntlMessages from "util/IntlMessages";

const PrivacyPolicy = ({ language }) => {
  return (
    <CustomScrollbars>
      <h1
        style={{
          paddingInlineStart: 32,
          marginTop: 40,
          marginBottom: 0,
          fontsize: 32,
        }}
      >
        <IntlMessages IntlMessages id={"privacyPolicy"} />
      </h1>
      {language === "en" ? (
        <div style={{ padding: 32 }}>
          <p>
            Welcome to CALLVITA/SRVY’s website and online services
            (“CALLVITA/SRVY Site” or the “Site”), an online information and
            communications service provided by CALLVITA/SRVY in Norton,
            Massachusetts (“CALLVITA/SRVY” or “We” or “Us”). This policy is
            intended to cover the uses for all the Site, although additional
            conditions, restrictions and privacy policies may apply. BY USING
            THE SITE, YOU AGREE TO THESE TERMS OF USE; IF YOU DO NOT AGREE, DO
            NOT USE THE SITE. CALLVITA/SRVY reserves the right, at its sole
            discretion, to change, modify, add or remove portions of these Terms
            of Use, at any time. It is your responsibility to check these Terms
            of Use periodically for changes. Your continued use of the Site
            following the posting of changes will mean that you accept and agree
            to the changes.
          </p>

          <h3>Special Product disclaimer (Health care disclaimer)</h3>
          <p>
            Callvita/SRVY services are not intended for use by you if you are
            not legally authorized to practice your profession or the entity you
            are affiliated with is not authorized to practice its activities,
            and therefore your use of the company’s services is your
            acknowledgment of your legal capacity or the eligibility of the
            entity you represent to practice its activities The doctor
            acknowledges his full and absolute responsibility, whether criminal
            or civil, for any error in the diagnosis or treatment of any patient
            that you dealt with through Callvita/SRVY services, so that
            Callvita/SRVY will not, under any circumstances, be responsible for
            any medical error, professional malpractice, or negligence committed
            by the doctor. Regarding the patient's right, and that Callvita/SRVY
            is limited to its role as a mediator between the doctor and the
            patient only. Callvita/SRVY gives no recommendations or
            encouragements. We do not recommend or endorse any content,
            services, tests, doctors, products, procedures, opinions, or other
            information that may be found on Callvita/SRVY website/App.
            Callvita/SRVY is designed to be used properly, and for the user to
            commun,icate with a doctor or healthcare provider to provide advice.
            Reliance on the content or services provided by Callvita/SRVY is
            only at your own risk. Certain content and services, including posts
            by medical experts, may have been provided by third parties directly
            without being reviewed or approved by medical staff. We cannot
            guarantee that the content and services provided will help you
            achieve any specific goals or results Callvita/SRVY does not
            guarantee the accuracy of the content provided by a third party
            Callvita/SRVY does not have any control over the content and does
            not assume any responsibility regarding the content provided by
            third parties, and any opinions, data, products, services, or other
            information expressed or made available by third parties (including
            That medical experts) or users of the Callvita/SRVY website belong
            to these third parties or users only. We do not guarantee the
            accuracy or reliability of any opinions, data, or other information
            provided by any third party, and we do not confirm or guarantee that
            your use of the content displayed or referred to in Callvita/SRVY
            will not violate the rights of a third party that is not owned or
            sponsored by Callvita/SRVY.
          </p>

          <h3>Content</h3>
          <p>
            user interfaces, graphics, visual interfaces, logos, sounds, artwork
            and computer code (collectively, “Content”), including but not
            limited to the design, structure, selection, coordination,
            expression, “look and feel” and arrangement of such Content,
            contained on the Site is owned, controlled or licensed by or to
            CALLVITA/SRVY, and is protected by trade dress, and trademark laws,
            and various other intellectual property rights and unfair
            competition laws.
          </p>

          <h3>Intended for users 18 and older</h3>
          <p>
            {}
            The Site is intended for use by individuals 18 years of age or
            older. This website is not directed for use by children under the
            age of 18. Users under the age of 18 should get the assistance of a
            parent or guardian to use this site.
          </p>

          <h3>Accounts, Passwords and Security</h3>
          <p>
            In order to use most aspects of our Services, you must register for
            and maintain an active account (“Account”). You must be at least 18
            years of age to obtain an Account. Account registration requires you
            to submit to CALLVITA/SRVY certain personal information, such as
            your name, address, mobile phone number and age, as well as at least
            one valid payment method (either a credit card or accepted payment
            partner). You agree to maintain accurate, complete, and up-to-date
            information in your Account. Your failure to maintain accurate,
            complete, and up-to-date Account information, including having an
            invalid or expired payment method on file, may result in your
            inability to access or use the services. You are entirely
            responsible for maintaining the confidentiality of the information
            you hold for your account, including your password, and for any and
            all activity that occurs under your account as a result of your
            failing to keep this information secure and confidential. You agree
            to notify CALLVITA/SRVY immediately of any unauthorized use of your
            account or password, or any other breach of security.
          </p>

          <h3>Payment information</h3>
          <p>
            You are expected to agree to pay for the service, and that
            Callvita/SRVY may charge payments and any additional amounts
            (including any late taxes and fees, as the case may be) that may be
            owed by you or related to your account. You are responsible for the
            timely payment of all fees, and for you to pay all fees to service
            providers using a valid payment method. We accept online payments
            via third party that accept Visa and MasterCard credit cards, Meza
            and Fawry cash. Refund and free of charge cancellation policies are
            varies depending on the type of service provided. All policies are
            listed with every service individually. All refunds are excluding
            transaction fees.
          </p>

          <h3>Privacy Introduction</h3>
          <p>
            We take your privacy seriously, and we want you to know how we
            collect, use, share and protect your information. In addition to
            this privacy policy, users of the Callvita/SRVY site should consult
            site terms of use as well as any product specific terms and
            conditions that apply
          </p>

          <h3>Services</h3>

          <p>
            Callvita/SRVY’s goal is to maximize the use of technology to serve
            humanity. Through its’ multiple platforms, Callvita/SRVY is striving
            to connect people in need with those how can deliver in all spheres
            of life{}
          </p>

          <h3> Change</h3>
          <p>
            Callvita/SRVY (“we” or “us”) can modify this Privacy Policy, and if
            we make material changes to it, we will provide notice through our
            Services, or by other means, to provide you the opportunity to
            review the changes before they become effective. If you object to
            any changes, you may close your account. You acknowledge that your
            continued use of our Services after we publish or send a notice
            about our changes to this Privacy Policy means that the collection,
            use and sharing of your data is subject to the updated Privacy
            Policy. ,
          </p>

          <h3>Data We Collect</h3>
          <p>1. Data you provide to us</p>

          <h3>Registration</h3>
          <p>
            To create an account you need to provide data including your name,
            email address and/or mobile number, password, payment (e.g., credit
            card) and billing information.,
          </p>

          <h3>Related Companies and Other Services</h3>
          <p>
            We receive data about you when you use some of the other services
            provided by us or our affiliates.,
          </p>

          <h3>Others</h3>
          <p>
            Our Services are dynamic, and we often introduce new features, which
            may require the collection of new information. If we collect
            materially different personal data or materially change how we use
            your data, we will notify you and may also modify this Privacy
            Policy.,
          </p>

          <h3>How We Use Your Data</h3>
          <p>
            How we use your data will depend on which Services you use, how you
            use those Services and the choices you make in your settings. We use
            the data that we have about you to provide and personalize,
            including with the help of automated systems and inferences we make,
            our Services (including ads) so that they can be more relevant and
            useful to you and others.Services:We use your data to authorize
            access to our services;Stay connectedOur Services allow you to stay
            in touch and up to date with service providers and other
            professionals. To do so, you will “connect” with the professionals
            who you choose, and who also wish to “connect” with you.MarketingWe
            use data and content about Members for invitations and
            communications promoting membership and network growth, engagement,
            and our Services.,
          </p>

          <h3>Developing Services and ResearchService Development</h3>
          <p>
            We use data, to conduct research and development for the further
            development of our Services to provide you and others with a better,
            more intuitive, and personalized experience, drive membership growth
            and engagement on our Services, and help connect professionals to
            each other’s. Surveys Polls and surveys are conducted by us and
            others through our Services. You are not obligated to respond to
            polls or surveys, and you have choices about the information you
            provide. You may opt-out of survey invitations. Option provided in
            every campaign. ,
          </p>

          <h3>Customer Support</h3>
          <p>
            We use the data (which can include your communications) to
            investigate, respond to and resolve complaints and Service issues.
            Security and Investigations. We use your data (including your
            communications) if we think it is necessary for security purposes or
            to investigate possible fraud or other violations of our User
            Agreement or this Privacy Policy and/or attempts to harm our
            members.,
          </p>

          <h3>Legal Disclosures</h3>
          <p>
            We may need to share your data when we believe it is required by law
            or to help protect the rights and safety of you, us or others. It is
            possible that we will need to disclose information about you when
            required by law, subpoena, or other legal process or if we have a
            good faith belief that disclosure is reasonably necessary to (1)
            investigate, prevent, or take action regarding suspected or actual
            illegal activities or to assist government enforcement agencies; (2)
            enforce our agreements with you, (3) investigate and defend
            ourselves against any third-party claims or allegations, (4) protect
            the security or integrity of our Service (such as by sharing with
            companies facing similar threats); or (5) exercise or protect the
            rights and safety of Callvita/SRVY, our Members, personnel, or
            others. We attempt to notify Members about legal demands for their
            personal data when appropriate in our judgment, unless prohibited by
            law or court order or when the request is an emergency. We may
            dispute such demands when we believe, in our discretion, that the
            requests are overbroad, vague or lack proper authority, but we do
            not promise to challenge every demand.,
          </p>

          <h3> in Control or Sale</h3>
          <p>
            We can also share your personal data as part of a sale, merger or
            change in control, or in preparation for any of these events. Any
            other entity which buys us, or part of our business will have the
            right to continue to use your data, but only in the manner set out
            in this Privacy Policy unless you agree otherwise.,
          </p>

          <h3>Data Retention</h3>
          <p>
            We retain your personal data while your account is in existence or
            as needed to provide you Services. This includes data you or others
            provided to us and data generated or inferred from your use of our
            Services. Even if you only use our Services when looking for a new
            provider few years, we will retain your information and keep your
            profile open until you decide to close your account. In some cases,
            we choose to retain certain information in a depersonalized or
            aggregated form.,
          </p>

          <h3>Rights to Access and Control Your Personal Data</h3>
          <p>
            You can access or delete your personal data. You have many choices
            about how your data is collected, used and shared. We provide many
            choices about the collection, use and sharing of your data, from
            deleting or correcting data you include in your profile and
            controlling the visibility of your account opt-outs and
            communication controls. We offer you settings to control and manage
            the personal data we have about you.,
          </p>

          <h3>Account Closure</h3>
          <p>
            If you choose to close your account, your personal data will
            generally stop being visible to others on our Services within 24
            hours. We generally delete closed account information within 30 days
            of account closure, except as noted below. We retain your personal
            data even after you have closed your account if reasonably necessary
            to comply with our legal obligations (including law enforcement
            requests), meet regulatory requirements, resolve disputes, maintain
            security, prevent fraud and abuse, enforce our User Agreement, or
            fulfill your request to “unsubscribe” from further messages from us.
            We will retain de-personalized information after your account has
            been closed. Information you have shared with others (e.g., through
            InMail, updates or group posts) will remain visible after you closed
            your account or deleted the information from your own profile or
            mailbox, and we do not control data that other Members copied out of
            our Services.,
          </p>

          <h3>Other Important Information5.1. Security</h3>
          <p>
            We monitor for and try to prevent security breaches. Please use the
            security features available through our Services. We implement
            security safeguards designed to protect your data. We regularly
            monitor our systems for possible vulnerabilities and attacks.
            However, we cannot warrant the security of any information that you
            send us. There is no guarantee that data may not be accessed,
            disclosed, altered, or destroyed by breach of any of our physical,
            technical, or managerial safeguards,
          </p>

          <h3>5.2. Cross-Border Data Transfers</h3>
          <p>
            We store and use your data outside your country. We process data
            both inside and outside of the United States and rely on legally
            provided mechanisms to lawfully transfer data across borders.
            Countries where we process data may have laws which are different,
            and potentially not as protective, as the laws of your own country.,
          </p>

          <h3>5.3. Direct Marketing and Do Not Track Signals</h3>
          <p>
            Our statements regarding direct marketing and “do not track”
            signals. We currently do not share personal data with third parties
            for their direct marketing purposes without permission.,
          </p>

          <h3>5.4. Contact Information</h3>
          <p>
            You can contact us or use other options to resolve any complaints.
            If you have questions or complaints regarding this Policy, please
            first contact Callvita/SRVY online. You can also reach us by
            physical mail. If contacting us does not resolve your complaint, you
            have more options. Please contact us at info@callvita.com,
          </p>

          <h3>Disclaimers</h3>
          <p>
            CALLVITA/SRVY DOES NOT PROMISE THAT THE SITE OR ANY CONTENT, SERVICE
            OR FEATURE OF THE SITE WILL BE ERROR-FREE OR UNINTERRUPTED, OR THAT
            ANY DEFECTS WILL BE CORRECTED, OR THAT YOUR USE OF THE SITE WILL
            PROVIDE SPECIFIC RESULTS. THE SITE AND ITS CONTENT ARE DELIVERED ON
            AN “AS-IS” AND “AS-AVAILABLE” BASIS. ALL INFORMATION PROVIDED ON THE
            SITE IS SUBJECT TO CHANGE WITHOUT NOTICE. CALLVITA/SRVY CANNOT
            ENSURE THAT ANY FILES OR OTHER DATA YOU DOWNLOAD FROM THE SITE WILL
            BE FREE OF VIRUSES OR CONTAMINATION OR DESTRUCTIVE FEATURES.
            CALLVITA/SRVY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
            INCLUDING ANY WARRANTIES OF ACCURACY, NON-INFRINGEMENT,
            MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. CALLVITA/SRVY
            DISCLAIMS ANY AND ALL LIABILITY FOR THE ACTS, OMISSIONS AND CONDUCT
            OF ANY THIRD PARTIES IN CONNECTION WITH OR RELATED TO YOUR USE OF
            THE SITE AND/OR ANY CALLVITA/SRVY SERVICES. YOU ASSUME TOTAL
            RESPONSIBILITY FOR YOUR USE OF THE SITE. YOUR SOLE REMEDY AGAINST
            CALLVITA/SRVY FOR DISSATISFACTION WITH THE SITE OR ANY CONTENT IS TO
            STOP USING THE SITE OR ANY SUCH CONTENT. THIS LIMITATION OF RELIEF
            IS A PART OF THE BARGAIN BETWEEN THE PARTIES.,
          </p>

          <h3>Limitation of liability</h3>
          <p>
            THE USE OF THE SITE IS ENTIRELY AT YOUR OWN RISK, AND IN NO EVENT
            SHALL CALLVITA/SRVY BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
            CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE, OR ANY OTHER MONETARY
            OR OTHER DAMAGES, FEES, FINES, PENALTIES, OR LIABILITIES ARISING OUT
            OF OR RELATING IN ANY WAY TO THIS SERVICE, OR SITES ACCESSED THROUGH
            THIS SERVICE, AND/OR CONTENT OR INFORMATION PROVIDED HEREIN. YOUR
            SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH THE SERVICE IS TO
            STOP USING THE SERVICE. USER HEREBY ACKNOWLEDGES THAT THIS PARAGRAPH
            SHALL APPLY TO ALL CONTENT, MERCHANDISE AND SERVICES AVAILABLE
            THROUGH THE SITE.,
          </p>

          <h3>Indemnity</h3>
          <p>
            You agree to indemnify and hold Callvita/SRVY, its officers,
            directors, shareholders, predecessors, successors in interest,
            employees, agents, subsidiaries and affiliates, harmless from any
            demands, loss, liability, claims or expenses (including attorneys’
            fees), made against Callvita/SRVY by any third party due to or
            arising out of or in connection with your use of the Site,
          </p>

          <h3>Intellectual property</h3>
          <p>
            The marks “Callvita/SRVY,” and all its products are trademarks
            and/or service marks of Callvita/SRVY. The content and design of
            Callvita/SRVY Site is protected by U.S. and international copyright
            laws. You may not copy, reproduce, republish, upload, post, display,
            transmit or frame any of these materials without prior written
            consent from the copyright owners.,
          </p>

          <h3>Termination of use</h3>
          <p>
            Callvita/SRVY may, in its sole discretion, terminate your account or
            your use of the Site at any time. Callvita/SRVY reserves the right
            to change, suspend or discontinue all or any aspects of the Site at
            any time without prior notice.,
          </p>

          <h3>Governing Law; Arbitration</h3>
          <p>
            You agree that all matters relating to your access to or use of the
            Site, including all disputes, will be governed by the laws of the
            United States and by the laws of the State of Massachusetts without
            regard to its conflicts of law’s provisions. In the event of any
            controversy or dispute between Callvita/SRVY and you arising out of
            or in connection with your use of the Site, the parties shall
            attempt, promptly and in good faith, to resolve any such dispute. If
            we are unable to resolve any such dispute within a reasonable time
            (not to exceed thirty (30) days), then either party may submit such
            controversy or dispute to mediation. If the dispute cannot be
            resolved through mediation, then the parties shall be free to pursue
            any right or remedy available to them under applicable law.
          </p>

          <h3>Violation of These Terms of Use</h3>
          <p>
            Callvita/SRVY may disclose any information we have about you
            (including your identity) if we determine that such disclosure is
            necessary in connection with any investigation or complaint
            regarding your use of the Site, or to identify, contact or bring
            legal action against someone who may be causing injury to or
            interference with (either intentionally or unintentionally)
            Callvita/SRVY’s rights or property, or the rights or property of
            visitors to or users of the Site, including Callvita/SRVY’s
            customers. Callvita/SRVY always reserves the right to disclose any
            information that Callvita/SRVY deems necessary to comply with any
            applicable law, regulation, legal process or governmental request.
            Callvita/SRVY also may disclose your information when Callvita/SRVY
            determines that applicable law requires or permits such disclosure,
            including exchanging information with other companies and
            organizations for fraud protection purposes. You acknowledge and
            agree that Callvita/SRVY may preserve any transmittal or
            communication by you with Callvita/SRVY through the Site or any
            service offered on or through the Site, and may also disclose such
            data if required to do so by law or Callvita/SRVY determines that
            such preservation or disclosure is reasonably necessary to (1)
            comply with legal process, (2) enforce these Terms of Use, (3)
            respond to claims that any such data violates the rights of others,
            or (4) protect the rights, property or personal safety of
            Callvita/SRVY, its employees, users of or visitors to the Site, and
            the public. You agree that Callvita/SRVY may, in its sole discretion
            and without prior notice, terminate your access to the Site and/or
            block your future access to the Site if we determine that you have
            violated these Terms of Use or other agreements or guidelines which
            may be associated with your use of the Site. You also agree that any
            violation by you of these Terms of Use will constitute an unlawful
            and unfair business practice, and will cause irreparable harm to
            Callvita/SRVY, for which monetary damages would be inadequate, and
            you consent to Callvita/SRVY obtaining any injunctive or equitable
            relief that Callvita/SRVY deems necessary or appropriate in such
            circumstances. These remedies are in addition to any other remedies
            Callvita/SRVY may have at law or in equity. You agree that
            Callvita/SRVY may, in its sole discretion and without prior notice,
            terminate your access to the Site, for cause, which includes (but is
            not limited to) (1) requests by law enforcement or other government
            agencies, (2) a request by you (self-initiated account deletions),
            (3) discontinuance or material modification of the Site or any
            service offered on or through the Site, or (4) unexpected technical
            issues or problems. If Callvita/SRVY does take any legal action
            against you as a result of your violation of these Terms of Use,
            Callvita/SRVY will be entitled to recover from you, and you agree to
            pay, all reasonable attorneys’ fees and costs of such action, in
            addition to any other relief granted to Callvita/SRVY. You agree
            that Callvita/SRVY will not be liable to you or to any third party
            for termination of your access to the Site as a result of any
            violation of these Terms of Use.
          </p>

          <h3>Miscellaneous</h3>
          <p>
            You may not use or export or re-export any Content or any copy or
            adaptation of such Content, or any product or service offered on the
            Site, in violation of any applicable laws or regulations, including
            without limitation United States export laws and regulations. If any
            of the provisions of these Terms of Use are held by a court or other
            tribunal of competent jurisdiction to be void or unenforceable, such
            provisions shall be limited or eliminated to the minimum extent
            necessary and replaced with a valid provision that best embodies the
            intent of these Terms of Use, so that these Terms of Use shall
            remain in full force and effect. These Terms of Use constitute the
            entire agreement between you and Callvita/SRVY with regard to your
            use of the Site, and any and all other written or oral agreements or
            understandings previously existing between you and Callvita/SRVY
            with respect to such use are hereby superseded and cancelled.
          </p>

          <h3>Feedback</h3>
          <p>
            If you have any feedback / questions about our Services, please
            contact us at info@callvita.com
          </p>
        </div>
      ) : (
        <div style={{ padding: 32 }}>
          <p>
            مرحبًا بكم في موقع (كالفيتا / سرفي) وخدماته عبر الإنترنت (“(كالفيتا
            / سرفي) موقع” أو “الموقع”) ، وهي خدمة معلومات واتصالات عبر الإنترنت
            تقدمها (كالفيتا / سرفي) في نورتون ، ماساتشوستس (“(كالفيتا / سرفي)
            نحن” أو “ نحن”) . تهدف هذه السياسة إلى تغطية الاستخدامات لجميع
            المواقع ، على الرغم من أنه قد يتم تطبيق شروط وقيود وسياسات خصوصية
            إضافية. باستخدام الموقع ، فإنك توافق على شروط الاستخدام هذه ؛ إذا
            كنت لا توافق ، فلا تستخدم الموقع. تحتفظ (كالفيتا / سرفي) بالحق ،
            وفقًا لتقديرها الخاص ، في تغيير أو تعديل أو إضافة أو إزالة أجزاء من
            شروط الاستخدام هذه ، في أي وقت. تقع على عاتقك مسؤولية مراجعة شروط
            الاستخدام هذه بشكل دوري لمعرفة التغييرات. استمرار استخدامك للموقع
            بعد نشر التغييرات يعني أنك تقبل التغييرات وتوافق عليها.
          </p>
          <h3> إخلاء مسؤولية منتج خاص (إخلاء مسؤولية الرعاية الصحية)</h3>
          <p>
            دمات (كالفيتا / سرفي) ليست مخصصة للاستخدام بواسطتك إذا لم تكن مخولًا
            قانونًا لممارسة مهنتك أو أن الكيان الذي تنتسب إليه غير مصرح له
            بممارسة أنشطته ، وبالتالي فإن استخدامك لخدمات الشركة هو إقرار منك
            بأهليتك القانونية أو أهلية الكيان الذي تمثله لممارسة أنشطتهيقر
            الطبيب بمسؤوليته الكاملة والمطلقة ، سواء جنائية أو مدنية ، عن أي خطأ
            في تشخيص أو علاج أي مريض تعاملت معه من خلال خدمات (كالفيتا / سرفي) ،
            حتى لا تكون (كالفيتا / سرفي) مسؤولة تحت أي ظرف من الظروف عن أي خطأ
            طبي ، سوء التصرف المهني أو الإهمال من قبل الطبيب. فيما يخص حق المريض
            ، وان يقتصر دور كالفيتا على دورها كوسيط بين الطبيب والمريض فقط.لا
            تقدم (كالفيتا / سرفي) أي توصيات أو تشجيعات. لا نوصي أو نصادق على أي
            محتوى أو خدمات أو اختبارات أو أطباء أو منتجات أو إجراءات أو آراء أو
            معلومات أخرى يمكن العثور عليها على موقع / تطبيق (كالفيتا / سرفي). تم
            تصميم (كالفيتا / سرفي) ليتم استخدامه بشكل صحيح ، وللمستخدم للتواصل
            مع الطبيب أو مقدم الرعاية الصحية لتقديم المشورة. الاعتماد على
            المحتوى أو الخدمات التي تقدمها (كالفيتا / سرفي) هو فقط على مسؤوليتك
            الخاصة. قد يتم توفير بعض المحتويات والخدمات ، بما في ذلك مشاركات
            الخبراء الطبيين ، من قبل أطراف ثالثة مباشرة دون مراجعتها أو الموافقة
            عليها من قبل الأطباء العاملين. لا يمكننا ضمان أن المحتوى والخدمات
            المقدمة سوف تساعدك في تحقيق أي أهداف أو نتائج محددةسيتم التحقيق في
            أي شكوى من عملائنا ، ولدى كالفيتا الحق الكامل ووفقًا لتقديرها الخاص
            فقط ان لا تدفع لمزود الخدمة إذا اكتشفنا أن الخدمة لم يتم تقديمها
            بشكل مناسبلا تضمن (كالفيتا / سرفي) دقة المحتوى المقدم من طرف ثالثلا
            تملك (كالفيتا / سرفي) أي سيطرة على المحتوى ولا تتحمل أي مسؤولية فيما
            يتعلق بالمحتوى المقدم من أطراف ثالثة ، وأي آراء أو بيانات أو منتجات
            أو خدمات أو معلومات أخرى صريحة أو متاحة من قبل أطراف ثالثة (بما في
            ذلك الخبراء الطبيون) أو مستخدمو موقع (كالفيتا / سرفي) ينتمون إلى هذه
            الأطراف الثالثة أو المستخدمين فقط. لا نضمن دقة أو موثوقية أي آراء أو
            بيانات أو معلومات أخرى مقدمة من أي طرف ثالث ، ولا نؤكد أو نضمن أن
            استخدامك للمحتوى المعروض أو المشار إليه في (كالفيتا / سرفي) لن ينتهك
            حقوق الطرف الثالث. طرف لا تملكه أو ترعاه (كالفيتا / سرفي).
          </p>
          <h3> المحتوى</h3>
          <p>
            ميع النصوص وواجهات المستخدم والرسومات والواجهات المرئية والشعارات
            والأصوات والأعمال الفنية ورموز الكمبيوتر (يشار إليها مجتمعة باسم
            ”المحتوى” ) ، بما في ذلك على سبيل المثال لا الحصر التصميم والهيكل
            والاختيار والتنسيق والتعبير و الشكل والمظهر والترتيب من هذا المحتوى
            الموجود على الموقع مملوك أو مسيطر عليه أو مرخص لشركة (كالفيتا /
            سرفي) ، وهو محمي بالزي التجاري وقوانين العلامات التجارية ومختلف حقوق
            الملكية الفكرية الأخرى وقوانين المنافسة غير العادلة.
          </p>
          <h3> مخصص للمستخدمين من عمر ١٨ عامًا فما فوق</h3>
          <p>
            ذا الموقع مخصص للاستخدام من قبل الأفراد الذين تبلغ أعمارهم ١٨ عامًا
            أو أكبر. هذا الموقع غير موجه للاستخدام من قبل الأطفال الذين تقل
            أعمارهم عن ١٨ عامًا. يجب على المستخدمين الذين تقل أعمارهم عن ١٨
            عامًا الحصول على مساعدة أحد الوالدين أو الوصي لاستخدام هذا الموقع.
          </p>
          <h3> الحسابات وكلمات المرور والأمان</h3>
          <p>
            ن أجل استخدام معظم جوانب خدماتنا ، يجب عليك التسجيل للحصول على حساب
            نشط والحفاظ عليه (”الحساب”). يجب أن يكون عمرك ١٨ عامًا على الأقل
            للحصول على حساب. يتطلب تسجيل الحساب منك تقديم معلومات شخصية معينة
            إلى (كالفيتا / سرفي) ، مثل اسمك وعنوانك ورقم هاتفك المحمول وعمرك ،
            بالإضافة إلى طريقة دفع صالحة واحدة على الأقل (إما بطاقة ائتمان أو
            شريك دفع مقبول). أنت توافق على الاحتفاظ بمعلومات دقيقة وكاملة وحديثة
            في حسابك. قد يؤدي إخفاقك في الحفاظ على معلومات دقيقة وكاملة وحديثة
            عن الحساب ، بما في ذلك وجود طريقة دفع غير صالحة أو منتهية الصلاحية
            في الملف ، إلى عدم قدرتك على الوصول إلى الخدمات أو استخدامها. أنت
            مسؤول مسؤولية كاملة عن الحفاظ على سرية المعلومات التي تحتفظ بها
            لحسابك ، بما في ذلك كلمة المرور الخاصة بك ، وعن أي وكل نشاط يحدث تحت
            حسابك نتيجة لفشلك في الحفاظ على هذه المعلومات آمنة وسرية. أنت توافق
            على إخطار (كالفيتا / سرفي) فورًا بأي استخدام غير مصرح به لحسابك أو
            كلمة مرورك ، أو أي خرق آخر للأمن.
          </p>
          <h3> معلومات الدفع</h3>
          <p>
            ن المتوقع أن توافق على الدفع مقابل الخدمة ، وأن (كالفيتا / سرفي) قد
            تفرض مدفوعات وأي مبالغ إضافية (بما في ذلك أي ضرائب ورسوم متأخرة ،
            حسب الحالة) التي قد تكون مستحقة عليك أو متعلقة بحسابك. أنت مسؤول عن
            سداد جميع الرسوم في الوقت المناسب ، كما تتحمل مسؤولية دفع جميع
            الرسوم لمقدمي الخدمة باستخدام طريقة دفع صالحة. نقبل الدفع عبر
            الإنترنت عن طريق طرف ثالث يقبل بطاقات ائتمان فيزا و ماستركارد و ميزا
            و فوري كاش . تختلف سياسات الاسترداد والإلغاء المجاني حسب نوع الخدمة
            المقدمة. يتم سرد جميع السياسات مع كل خدمة على حدة. جميع المبالغ
            المستردة لا تشمل رسوم المعاملات.
          </p>
          <h3> مقدمة حول الخصوصية</h3>
          <p>
            حن نأخذ خصوصيتك على محمل الجد ، ونريدك أن تعرف كيف نجمع معلوماتك
            ونستخدمها ونشاركها ونحميها. بالإضافة إلى سياسة الخصوصية هذه ، يجب
            على مستخدمي موقع (كالفيتا / سرفي) الرجوع إلى شروط استخدام الموقع
            بالإضافة إلى أي شروط وأحكام خاصة بالمنتج تنطبق.
          </p>
          <h3> خدمات</h3>
          <p>
            دف (كالفيتا / سرفي) هو تعظيم استخدام التكنولوجيا لخدمة البشرية. من
            خلال منصاتها المتعددة ، تسعى (كالفيتا / سرفي) جاهدة لربط الأشخاص
            المحتاجين بأولئك الذين يمكنهم تقديم المساعدة في جميع مجالات الحياة.
          </p>
          <h3> تغييرات</h3>
          <p>
            مكن لـ (كالفيتا / سرفي) (”نحن” أو ”نحن”) تعديل سياسة الخصوصية هذه ،
            وإذا أجرينا تغييرات جوهرية عليها ، فسنقدم إشعارًا من خلال خدماتنا ،
            أو بوسائل أخرى ، لنوفر لك الفرصة لمراجعة التغييرات قبل أن تصبح فعال.
            إذا كنت تعترض على أي تغييرات ، فيمكنك إغلاق حسابك. أنت تقر بأن
            استمرارك في استخدام خدماتنا بعد أن ننشر أو نرسل إشعارًا بشأن
            التغييرات التي أجريناها على سياسة الخصوصية هذه يعني أن جمع واستخدام
            ومشاركة بياناتك يخضع لسياسة الخصوصية المحدثة.
          </p>
          <h3>البيانات التي نجمعها</h3>
          <p> ١. البيانات التي تقدمها لنا</p>
          <h3>التسجيل</h3>
          <p>
            لإنشاء حساب ، تحتاج إلى تقديم بيانات بما في ذلك اسمك وعنوان بريدك
            الإلكتروني و / أو رقم هاتفك المحمول وكلمة المرور والدفع (على سبيل
            المثال ، بطاقة الائتمان) ومعلومات الفواتير.
          </p>
          <h3>الشركات ذات الصلة والخدمات الأخرى</h3>
          <p>
            نتلقى بيانات عنك عندما تستخدم بعض الخدمات الأخرى التي نقدمها نحن أو
            الشركات التابعة لنا.
          </p>
          <h3>
            نتلقى بيانات عنك عندما تستخدم بعض الخدمات الأخرى التي نقدمها نحن أو
            الشركات التابعة لنا.
          </h3>
          <p>
            خدماتنا ديناميكية ، وغالبًا ما نقدم ميزات جديدة ، والتي قد تتطلب جمع
            معلومات جديدة. إذا قمنا بجمع بيانات شخصية مختلفة ماديًا أو قمنا
            بتغيير طريقة استخدامنا لبياناتك ماديًا ، فسنخطرك وقد نقوم أيضًا
            بتعديل سياسة الخصوصية هذه.
          </p>
          <h3>كيف نستخدم بياناتك</h3>
          <p>
            تعتمد كيفية استخدامنا لبياناتك على الخدمات التي تستخدمها وكيفية
            استخدامك لتلك الخدمات والخيارات التي تحددها في إعداداتك. نستخدم
            البيانات التي نمتلكها عنك لتقديمها وتخصيصها ، بما في ذلك بمساعدة
            الأنظمة الآلية والاستدلالات التي نقدمها ، خدماتنا (بما في ذلك
            الإعلانات) حتى تكون أكثر صلة وإفادة لك وللآخرين.
          </p>
          <h3>خدمات:</h3>
          <p>
            نستخدم بياناتك للسماح بالوصول إلى خدماتنا ؛ابق على اتصالتتيح لك
            خدماتنا البقاء على اتصال ومواكبة مع مزودي الخدمة وغيرهم من المهنيين.
            للقيام بذلك ، سوف ”تتصل” بالمحترفين الذين تختارهم والذين يرغبون
            أيضًا في ”الاتصال” معك.تسويقنستخدم البيانات والمحتوى الخاص بالأعضاء
            للدعوات والاتصالات التي تعزز العضوية ونمو الشبكة والمشاركة
            وخدماتنا.تطوير الخدمات والبحوثتطوير الخدمةنستخدم البيانات لإجراء
            البحث والتطوير لمزيد من التطوير لخدماتنا من أجل تزويدك أنت والآخرين
            بتجربة أفضل وأكثر بديهية ومخصصة ، وتعزيز نمو العضوية والمشاركة في
            خدماتنا ، والمساعدة في ربط المحترفين ببعضهم البعض. الاستطلاعات يتم
            إجراء الاستطلاعات والاستطلاعات بواسطتنا والآخرين من خلال خدماتنا.
            لست ملزمًا بالرد على استطلاعات الرأي أو الاستطلاعات ، ولديك خيارات
            بشأن المعلومات التي تقدمها. يمكنك الانسحاب من دعوات الاستطلاع.
            الخيار المقدم في كل حملة.
          </p>
          <h3>دعم العملاء</h3>
          <p>
            نستخدم البيانات (التي يمكن أن تشمل اتصالاتك) للتحقيق في الشكاوى
            ومشكلات الخدمة والرد عليها وحلها. الأمن والتحقيقات. نحن نستخدم
            بياناتك (بما في ذلك اتصالاتك) إذا اعتقدنا أنها ضرورية لأغراض أمنية
            أو للتحقيق في احتيال محتمل أو انتهاكات أخرى لاتفاقية المستخدم أو
            سياسة الخصوصية هذه و / أو محاولات الإضرار بأعضائنا.
          </p>
          <h3>الإفصاحات القانونية</h3>

          <p>
            قد نحتاج إلى مشاركة بياناتك عندما نعتقد أنها مطلوبة بموجب القانون أو
            للمساعدة في حماية حقوقك وسلامتك أو لنا أو للآخرين. من المحتمل أننا
            سنحتاج إلى الكشف عن معلومات عنك عندما يقتضي القانون أو أمر استدعاء
            أو أي إجراء قانوني آخر أو إذا كان لدينا اعتقاد حسن النية أن الكشف
            ضروري بشكل معقول (١) للتحقيق أو المنع أو اتخاذ إجراء بشأن الاشتباه
            أو الأنشطة غير القانونية الفعلية أو لمساعدة وكالات الإنفاذ الحكومية
            ؛ (٢) تنفيذ اتفاقياتنا معك ، (٣)التحقيق والدفاع عن أنفسنا ضد أي
            ادعاءات أو ادعاءات من طرف ثالث ، (4) حماية أمن أو نزاهة خدمتنا (مثل
            المشاركة مع الشركات التي تواجه تهديدات مماثلة) ؛ أو (5) ممارسة أو
            حماية حقوق (كالفيتا / سرفي) أو أعضائنا أو موظفينا أو غيرهم أو
            حمايتهم. نحاول إبلاغ الأعضاء بالمطالب القانونية لبياناتهم الشخصية
            عندما يكون ذلك مناسبًا في حكمنا ، ما لم يكن محظورًا بموجب القانون أو
            بأمر من المحكمة أو عندما يكون الطلب طارئًا. قد نعارض مثل هذه الطلبات
            عندما نعتقد ، وفقًا لتقديرنا ، أن الطلبات مفرطة أو غامضة أو تفتقر
            إلى السلطة المناسبة ، لكننا لا نعد بالطعن في كل طلب.
          </p>
          <h3>التغيير في السيطرة أو البيع</h3>
          <p>
            يمكننا أيضًا مشاركة بياناتك الشخصية كجزء من عملية بيع أو دمج أو
            تغيير في السيطرة ، أو استعدادًا لأي من هذه الأحداث. أي كيان آخر
            يشترينا ، أو جزء من أعمالنا سيكون له الحق في الاستمرار في استخدام
            بياناتك ، ولكن فقط بالطريقة المنصوص عليها في سياسة الخصوصية هذه ما
            لم توافق على خلاف ذلك.
          </p>
          <h3>الاحتفاظ بالبيانات</h3>

          <p>
            نحتفظ ببياناتك الشخصية أثناء وجود حسابك أو حسب الحاجة لتزويدك
            بالخدمات. يتضمن ذلك البيانات التي قدمتها أنت أو الآخرين إلينا
            والبيانات التي تم إنشاؤها أو استنتاجها من استخدامك لخدماتنا. حتى إذا
            كنت تستخدم خدماتنا فقط عند البحث عن مزود جديد لبضع سنوات ، فسنحتفظ
            بمعلوماتك ونبقي ملفك الشخصي مفتوحًا حتى تقرر إغلاق حسابك. في بعض
            الحالات ، نختار الاحتفاظ بمعلومات معينة بصيغة مجمعة أو غير شخصية.
          </p>

          <h3>حقوق الوصول إلى بياناتك الشخصية والتحكم فيها</h3>
          <p>
            يمكنك الوصول إلى بياناتك الشخصية أو حذفها. لديك العديد من الخيارات
            حول كيفية جمع بياناتك واستخدامها ومشاركتها. نحن نقدم العديد من
            الخيارات حول جمع واستخدام ومشاركة بياناتك ، من حذف أو تصحيح البيانات
            التي تدرجها في ملفك الشخصي والتحكم في رؤية إلغاء الاشتراك في حسابك
            وعناصر التحكم في الاتصال. نقدم لك إعدادات للتحكم في البيانات الشخصية
            التي لدينا عنك وإدارتها.
          </p>
          <h3>اغلاق الحساب</h3>
          <p>
            ذا اخترت إغلاق حسابك ، فستتوقف بياناتك الشخصية بشكل عام عن الظهور
            للآخرين على خدماتنا في غضون ٢٤ ساعة. نحذف معلومات الحساب المغلق بشكل
            عام في غضون ٣٠ يومًا من إغلاق الحساب ، باستثناء ما هو مذكور أدناه.
            نحتفظ ببياناتك الشخصية حتى بعد إغلاق حسابك إذا كان ذلك ضروريًا بشكل
            معقول للامتثال لالتزاماتنا القانونية (بما في ذلك طلبات إنفاذ
            القانون) ، أو تلبية المتطلبات التنظيمية ، أو حل النزاعات ، أو الحفاظ
            على الأمن ، أو منع الاحتيال وإساءة الاستخدام ، أو فرض اتفاقية
            المستخدم الخاصة بنا ، أو الوفاء طلبك ”لإلغاء الاشتراك” من رسائل أخرى
            منا. سنحتفظ بالمعلومات غير الشخصية بعد إغلاق حسابك. ستظل المعلومات
            التي قمت بمشاركتها مع الآخرين (على سبيل المثال ، من خلال InMail أو
            التحديثات أو منشورات المجموعة) مرئية بعد إغلاق حسابك أو حذف
            المعلومات من ملف التعريف الخاص بك أو صندوق البريد الخاص بك ، ولا
            نتحكم في البيانات التي نسخها الأعضاء الآخرون من خدماتنا .
          </p>
          <h3>معلومات هامة أخرى١.٥ الأمان</h3>
          <p>
            نحن نراقب ونحاول منع الانتهاكات الأمنية. يرجى استخدام ميزات الأمان
            المتاحة من خلال خدماتنا. نحن نطبق ضمانات أمنية مصممة لحماية بياناتك.
            نحن نراقب أنظمتنا بانتظام بحثًا عن نقاط الضعف والهجمات المحتملة. ومع
            ذلك ، لا يمكننا ضمان أمن أي معلومات ترسلها إلينا. لا يوجد ضمان بعدم
            إمكانية الوصول إلى البيانات أو الكشف عنها أو تغييرها أو إتلافها عن
            طريق انتهاك أي من الضمانات المادية أو الفنية أو الإدارية الخاصة بنا.
          </p>
          <h3>٢.٥. عمليات نقل البيانات عبر الحدود</h3>
          <p>
            نقوم بتخزين واستخدام البيانات الخاصة بك خارج بلدك. نقوم بمعالجة
            البيانات داخل وخارج الولايات المتحدة على حد سواء ونعتمد على الآليات
            المتوفرة قانونًا لنقل البيانات بشكل قانوني عبر الحدود. قد يكون لدى
            البلدان التي نعالج فيها البيانات قوانين مختلفة ، وربما لا تكون
            وقائية ، مثل قوانين بلدك.
          </p>
          <h3>٣.٥ التسويق المباشر وعدم تتبع الإشارات</h3>
          <p>
            بياناتنا المتعلقة بالتسويق المباشر وإشارات ”عدم التتبع”. لا نشارك
            حاليًا البيانات الشخصية مع جهات خارجية لأغراض التسويق المباشر الخاصة
            بهم دون إذن.
          </p>
          <h3>٤.٥. معلومات للتواصل</h3>
          <p>
            يمكنك الاتصال بنا أو استخدام خيارات أخرى لحل أي شكاوى. إذا كانت لديك
            أسئلة أو شكاوى بخصوص هذه السياسة ، فيرجى أولاً الاتصال بـ (كالفيتا /
            سرفي) عبر الإنترنت. يمكنك أيضًا الوصول إلينا عن طريق البريد العادي.
            إذا لم يؤد الاتصال بنا إلى حل شكواك ، فلديك المزيد من الخيارات. يرجى
            الاتصال بنا على info@callvita.com
          </p>
          <h3>إخلاء المسؤولية</h3>
          <p>
            لا تتعهد (كالفيتا / سرفي) بأن الموقع أو أي محتوى أو خدمة أو ميزة
            للموقع ستكون خالية من الأخطاء أو بدون انقطاع ، أو أنه سيتم تصحيح أي
            عيوب ، أو أن استخدامك للموقع سيوفر نتائج محددة. يتم تسليم الموقع
            ومحتوياته على أساس ”كما هو” و ”كما هو متاح”. جميع المعلومات المقدمة
            على الموقع عرضة للتغيير دون إشعار. لا تستطيع (كالفيتا / سرفي) ضمان
            خلو أي ملفات أو بيانات أخرى تقوم بتحميلها من الموقع من الفيروسات أو
            التلوث أو الميزات المدمرة. تخلي (كالفيتا / سرفي) مسؤوليتها عن جميع
            الضمانات ، الصريحة أو الضمنية ، بما في ذلك أي ضمانات بشأن الدقة وعدم
            الانتهاك وقابلية التسويق والملاءمة لغرض معين. تخلي (كالفيتا / سرفي)
            مسؤوليتها عن أي وجميع المسؤوليات عن الأفعال والإغفالات وسلوك أي طرف
            ثالث فيما يتعلق أو فيما يتعلق باستخدامك للموقع و / أو أي من خدمات
            (كالفيتا / سرفي). أنت تتحمل المسؤولية الكاملة عن استخدامك للموقع.
            العلاج الوحيد الذي تقدمه ضد (كالفيتا / سرفي) لعدم الرضا عن الموقع أو
            أي يتمثل المحتوى في إيقاف استخدام الموقع أو أي محتوى من هذا القبيل.
            يمثل تحديد التخفيف هذا جزءًا من الصفقة بين الطرفين.
          </p>
          <h3>تحديد المسؤولية</h3>
          <p>
            يكون استخدام الموقع على مسؤوليتك الخاصة تمامًا ، ولن تتحمل (كالفيتا
            / سرفي) بأي حال من الأحوال المسؤولية عن أي أضرار أو رسوم أو غرامات
            مباشرة أو غير مباشرة أو عرضية أو تبعية أو خاصة أو نموذجية أو عقابية
            أو أي أضرار مالية أو رسوم أو غرامات أخرى أو غيرها. أو المسؤوليات
            الناشئة عن هذه الخدمة أو المتعلقة بها بأي طريقة ، أو المواقع التي تم
            الوصول إليها من خلال هذه الخدمة و / أو المحتوى أو المعلومات المقدمة
            هنا. يتمثل الحل الوحيد والحصري لعدم الرضا عن الخدمة في التوقف عن
            استخدام الخدمة. يقر المستخدم بموجبه أن هذه الفقرة تنطبق على جميع
            المحتويات والبضائع والخدمات المتوفرة من خلال الموقع.
          </p>
          <h3>التعويض</h3>
          <p>
            أنت توافق على تعويض (كالفيتا / سرفي) ومسؤوليها ومديريها ومساهميها
            وأسلافها وخلفائها في المصلحة والموظفين والوكلاء والشركات الفرعية
            والشركات التابعة لها واحتجازهم ، غير ضار من أي مطالب أو خسارة أو
            مسؤولية أو مطالبات أو نفقات (بما في ذلك أتعاب المحاماة) ، المقدمة ضد
            (كالفيتا / سرفي) من قبل أي طرف ثالث بسبب أو ناشئة عن أو فيما يتعلق
            باستخدامك للموقع.
          </p>
          <h3>الملكية الفكرية</h3>
          <p>
            العلامات ”(كالفيتا / سرفي)” وجميع منتجاتها هي علامات تجارية و / أو
            علامات خدمة لـ (كالفيتا / سرفي). محتوى وتصميم موقع (كالفيتا / سرفي)
            محمي بموجب قوانين حقوق النشر الأمريكية والدولية. لا يجوز لك نسخ أو
            إعادة إنتاج أو إعادة نشر أو تحميل أو نشر أو عرض أو نقل أو تأطير أي
            من هذه المواد دون موافقة كتابية مسبقة من مالكي حقوق النشر.
          </p>
          <h3>إنهاء الاستخدام</h3>
          <p>
            قد تقوم (كالفيتا / سرفي) ، وفقًا لتقديرها الخاص ، بإنهاء حسابك أو
            استخدامك للموقع في أي وقت. تحتفظ (كالفيتا / سرفي) بالحق في تغيير أو
            تعليق أو إيقاف كل أو أي جوانب من الموقع في أي وقت دون إشعار مسبق.
          </p>
          <h3>القانون الذي يحكم؛ تحكم</h3>
          <p>
            أنت توافق على أن جميع الأمور المتعلقة بوصولك إلى الموقع أو استخدامه
            ، بما في ذلك جميع النزاعات ، ستخضع لقوانين الولايات المتحدة وقوانين
            ولاية ماساتشوستس بغض النظر عن تعارضها مع أحكام القانون. في حالة وجود
            أي خلاف أو نزاع بين (كالفيتا / سرفي) وبينك ناشئ عن أو فيما يتعلق
            باستخدامك للموقع ، يجب على الأطراف محاولة حل أي نزاع من هذا القبيل
            على الفور وبحسن نية. إذا لم نتمكن من حل أي نزاع من هذا القبيل في
            غضون فترة زمنية معقولة (لا تتجاوز ثلاثين (30) يومًا) ، فيجوز لأي طرف
            تقديم مثل هذا الخلاف أو النزاع إلى الوساطة. إذا كان النزاع لا يمكن
            حله من خلال الوساطة ، ثم للأطراف الحرية في متابعة أي حق أو تعويض
            متاح لهم بموجب القانون المعمول به.
          </p>
          <h3>انتهاك شروط الاستخدام هذه</h3>
          <p>
            قد تكشف (كالفيتا / سرفي) عن أي معلومات لدينا عنك (بما في ذلك هويتك)
            إذا قررنا أن هذا الكشف ضروري فيما يتعلق بأي تحقيق أو شكوى بخصوص
            استخدامك للموقع ، أو لتحديد أو الاتصال أو رفع دعوى قانونية ضد شخص قد
            يكون التسبب في إصابة أو تدخل (سواء عن قصد أو عن غير قصد) في حقوق
            Callvita/SRVY أو ممتلكاتها ، أو حقوق أو ممتلكات زوار أو مستخدمي
            الموقع ، بما في ذلك عملاء Callvita/SRVY. تحتفظ Callvita/SRVY دائمًا
            بالحق في الكشف عن أي معلومات تراها Callvita/SRVY ضرورية للامتثال لأي
            قانون أو لائحة أو إجراء قانوني أو طلب حكومي معمول به. قد تكشف
            Callvita/SRVY أيضًا عن معلوماتك عندما تقرر Callvita/SRVY أن القانون
            المعمول به يتطلب أو يسمح بهذا الكشف ، بما في ذلك تبادل المعلومات مع
            الشركات والمؤسسات الأخرى لأغراض الحماية من الاحتيال. أنت تقر وتوافق
            على أنه يجوز لـ Callvita/SRVY الاحتفاظ بأي نقل أو اتصال من جانبك مع
            Callvita/SRVY من خلال الموقع أو أي خدمة معروضة على الموقع أو من
            خلاله ، وقد تكشف أيضًا عن هذه البيانات إذا لزم الأمر بموجب القانون
            أو تقرر Callvita/SRVY أن هذا الحفظ أو الكشف ضروريًا بشكل معقول (1)
            للامتثال للإجراءات القانونية ، (2) إنفاذ شروط الاستخدام هذه ، (3)
            الرد على الادعاءات بأن أيًا من هذه البيانات تنتهك حقوق الآخرين ، أو
            (4) حماية الحقوق أو الممتلكات أو السلامة الشخصية Callvita/SRVY
            وموظفيها ومستخدميها أو زوار الموقع والجمهور. أنت توافق على أنه يجوز
            لـ Callvita/SRVY ، وفقًا لتقديرها الخاص ودون إشعار مسبق ، إنهاء
            وصولك إلى الموقع و / أو منع وصولك المستقبلي إلى الموقع إذا قررنا أنك
            قد انتهكت شروط الاستخدام هذه أو اتفاقيات أو إرشادات أخرى قد تكون
            المرتبطة باستخدامك للموقع. أنت توافق أيضًا على أن أي انتهاك من جانبك
            لشروط الاستخدام هذه سيشكل ممارسة تجارية غير قانونية وغير عادلة ،
            وسيؤدي إلى ضرر لا يمكن إصلاحه لـ Callvita/SRVY ، حيث لن تكون الأضرار
            المالية كافية ، وتوافق على حصول Callvita/SRVY على أي أمر زجري أو
            تعويض عادل تعتبر Callvita/SRVY ضرورية أو مناسبة في مثل هذه الظروف.
            هذه العلاجات هي بالإضافة إلى أي علاجات أخرى قد توفرها Callvita/SRVY
            بموجب القانون أو في الإنصاف. أنت توافق على أنه يجوز لـ Callvita/SRVY
            ، وفقًا لتقديرها الخاص ودون إشعار مسبق ، إنهاء وصولك إلى الموقع ،
            لأسباب تشمل (على سبيل المثال لا الحصر) (1) الطلبات المقدمة من جهات
            إنفاذ القانون أو الوكالات الحكومية الأخرى ، (2) أ الطلب من جانبك
            (عمليات الحذف الذاتية للحساب) ، (3) التوقف أو التعديل المادي للموقع
            أو أي خدمة مقدمة على الموقع أو من خلاله ، أو (4) مشكلات أو مشكلات
            فنية غير متوقعة. إذا اتخذت Callvita/SRVY أي إجراء قانوني ضدك نتيجة
            لانتهاكك لشروط الاستخدام هذه ، فسيحق لـ Callvita/SRVY أن تسترد منك ،
            وتوافق على دفع جميع أتعاب المحاماة المعقولة وتكاليف هذا الإجراء ،
            بالإضافة إلى أي إعفاء آخر ممنوح لـ Callvita/SRVY. أنت توافق على أن
            Callvita/SRVY لن تكون مسؤولة تجاهك أو تجاه أي طرف ثالث عن إنهاء
            وصولك إلى الموقع نتيجة لأي انتهاك لشروط الاستخدام هذه.
          </p>
          <h3>متنوع</h3>
          <p>
            لا يجوز لك استخدام أو تصدير أو إعادة تصدير أي محتوى أو أي نسخة أو
            تعديل لهذا المحتوى ، أو أي منتج أو خدمة معروضة على الموقع ، بما
            ينتهك أي قوانين أو لوائح سارية ، بما في ذلك على سبيل المثال لا الحصر
            قوانين ولوائح التصدير في الولايات المتحدة. إذا اعتبرت محكمة أو محكمة
            أخرى ذات اختصاص قضائي أن أيًا من أحكام شروط الاستخدام هذه باطلاً أو
            غير قابل للتنفيذ ، فيجب تقييد هذه الأحكام أو إلغاؤها إلى الحد الأدنى
            الضروري واستبدالها بنص صالح يجسد النية على أفضل وجه من شروط
            الاستخدام هذه ، بحيث تظل شروط الاستخدام هذه سارية المفعول والتأثير
            الكامل. تشكل شروط الاستخدام هذه الاتفاقية الكاملة بينك وبين
            Callvita/SRVY فيما يتعلق باستخدامك للموقع ، وأي وجميع الاتفاقيات أو
            التفاهمات المكتوبة أو الشفوية الأخرى الموجودة سابقًا بينك وبين
            Callvita/SRVY فيما يتعلق بهذا الاستخدام يتم إلغاؤها بموجب هذا.
          </p>
          <h3>ردود الفعل</h3>
          <p>
            إذا كان لديك أي ملاحظات / أسئلة حول خدماتنا ، يرجى الاتصال بنا على
            info@callvita.com
          </p>
        </div>
      )}
    </CustomScrollbars>
  );
};

export default connect(({ settings }) => {
  return {
    language: settings.locale.locale,
  };
})(PrivacyPolicy);
