export class ListItem {
    constructor({ 
        id,
        name,
        createdAt,
        tags,
        createdByName,
        model 
    }){
        this.id = id;
        this.name = name;
        this.tags = tags;
        this.createdAt = createdAt;
        this.createdByName = createdByName;
        this.model = model;
    }
}