export const GET_EVENTS = "GET_EVENTS";
export const GET_EVENTS_RETURNED = "GET_EVENTS_RETURNED";
export const ADD_EVENT = "ADD_EVENT";
export const EVENT_ADDED = "EVENT_ADDED";
export const EVENT_EDIT_REQUEST = "EVENT_EDIT_REQUEST";
export const EVENT_UPDATED = "EVENT_UPDATED";
export const ITEM_SELECTED = "ITEM_SELECTED";
export const UPDATE_EVENT_FILTERS = "UPDATE_EVENT_FILTERS";
export const UPDATE_TIME_FILTERS = "UPDATE_TIME_FILTERS";
export const RESET_EDIT_EVENT_MODE = "RESET_EDIT_EVENT_MODE";

export const UPDATE_NOTE_TYPE = "UPDATE_NOTE_TYPE";
export const ADD_VITAL_NOTE = "ADD_VITAL_NOTE";
export const VITAL_NOTE_ADDED = "VITAL_NOTE_ADDED";
export const ADD_NOTE = "ADD_NOTE";
export const NOTE_ADDED = "NOTE_ADDED";
export const NOTE_DELETED = "NOTE_DELETED";
export const NOTE_DELETE_REQUEST = "NOTE_DELETE_REQUEST";
export const SAVE_EDIT_REQUEST = "SAVE_EDIT_REQUEST";
export const NOTE_EDITED = "NOTE_EDITED";
export const UPDATE_SELECTED_NOTE = "UPDATE_SELECTED_NOTE";
export const GET_NOTES_RETURNED = "GET_NOTES_RETURNED";
export const GET_NOTES_REQUEST = "GET_NOTES_REQUEST";
export const TRANSLATE_REQUEST = "TRANSLATE_REQUEST";
export const TRANSLATE_DONE = "TRANSLATE_DONE";
export const SAVE_COMMENT_REQUESTED = "SAVE_COMMENT_REQUESTED";
export const COMMENT_SAVED = "COMMENT_SAVED";
export const GET_COMMENTS_REQUEST = "GET_COMMENTS_REQUEST";
export const COMMENTS_RETURNED = "COMMENTS_RETURNED";
export const COMMENTS_LENGTH_REQUESTED = "COMMENTS_LENGTH_REQUESTED";
export const DELETE_COMMENT_REQUEST = "DELETE_COMMENT_REQUEST";
export const COMMENT_DELETED = "COMMENT_DELETED";
export const RESET_NOTE_MODAL = "RESET_NOTE_MODAL";
export const UPDATE_NOTE_INFO = "UPDATE_NOTE_INFO";
export const SEARCH_EVENTS = "SEARCH_EVENTS";
export const SEARCH_RESULT = "SEARCH_RESULT";
export const UPDATE_DRAFT_VITAL_NOTE = "UPDATE_DRAFT_VITAL_NOTE";
export const RESET_SUCCESS = "RESET_SUCCESS"
export const RESET_ERROR = "RESET_ERROR";


/** Vitals Customizations **/
export const GET_VITALS = "GET_VITALS";
export const VITALS_RETURNED = "VITALS_RETURNED";
export const RESET_VITAL_MODAL = "RESET_VITAL_MODA";
export const EDIT_VITAL_NOTE = "EDIT_VITAL_NOTE";
export const VITAL_NOTE_EDITED = "VITAL_NOTE_EDITED";
export const DELETE_VITALS_NOTE = "DELETE_VITALS_NOTE";
export const VITALS_NOTE_DELETED = "VITALS_NOTE_DELETED";