import {
	SAVE_USER_SUCCESS,
	UPDATE_BASIC_INFO,
	USER_EDIT_MODE,
	GET_USER_EDIT_RETURNED,
	GET_USER_EDIT,
	OPEN_BASIC_INFO_DIALOG,
	USER_UPDATED,
	CHECKED,
	UPDATE_BASIC_INFO_ERRORS
} from '../action/actionTypes';
import { SIGNOUT_USER_SUCCESS } from '../../../../../constants/ActionTypes';

const initialState = {
	draftUser: null,
	users: [],
	isEdit: false,
	loading: false,
	openBasicInfoDialog: false,
	allBasicInfoErrors: false,
	checked: true
}

export const saveUser = (state = initialState, action) => {
	switch (action.type) {
		case CHECKED:
			return {
				...state,
				checked: action.payload
			}

		case SAVE_USER_SUCCESS:
			const { error } = action.payload;
			if (!error) {
				return {
					...state,
					draftUser: null,
					openBasicInfoDialog: false,
					checked: true
				}
			}
			return {
				...state,
				checked: true
			}

		case UPDATE_BASIC_INFO: {
			return {
				...state,
				draftUser: {
					...state.draftUser,
					basicInfo: action.payload
				}
			}
		}

		case UPDATE_BASIC_INFO_ERRORS: {
			return {
				...state,
				allBasicInfoErrors: action.payload
			}
		}

		case USER_EDIT_MODE: {
			return {
				...state,
				isEdit: action.payload.isEdit
			}
		}

		case GET_USER_EDIT:
			return {
				...state,
				loading: true
			}

		case GET_USER_EDIT_RETURNED: {
			if (!action.payload.error) {
				return {
					...state,
					draftUser: action.payload.newUser,
					loading: false
				}
			} else {
				return {
					...state,
					loading: false
				}
			}
		}
		case OPEN_BASIC_INFO_DIALOG: {
			return {
				...state,
				openBasicInfoDialog: action.payload
			}
		}

		case USER_UPDATED:
			if (!action.payload.error) {
				return {
					...state,
					isEdit: false,
					draftUser: null,
					openBasicInfoDialog: false
				}
			}
			return {
				...state
			}

		case SIGNOUT_USER_SUCCESS:
			return initialState;

		default:
			return state
	}
}