import React from 'react';
import { ClinicListItem } from '../models/clinicListItem';
import IntlMessages from '../../../../../../util/IntlMessages';

export const clinicToCLinicListItem = clinic => {
  const { basicInfo, id } = clinic;
  const { clinicType, legalName, address1, city, province, phoneNumber, status } = basicInfo;
  const typeName = <IntlMessages id={`org.type.${clinicType}`} />
  
  const tags = [
    {
      label: "default.type",
      value: typeName
    },
    {
      label: "org.address",
      value: address1 + " " + province + " " + city
    },
    {
      label: "org.phoneNumber",
      value: phoneNumber
    }
  ]

	return new ClinicListItem({
    id,
    name: legalName,
    tags,
    status,
    model: clinic
	})
}